import React, { useEffect, useRef, useState, useMemo, useContext } from 'react'
import './CircleBackground.scss'
import { gsap } from 'gsap'
import AppContext, { DataAppContext } from '../../AppContext'
import classNames from 'classnames'

export const CircleBackground = () => {
  const context = useContext(AppContext) as DataAppContext
  if (!context) {
    throw new Error('App must be used within AppProvider')
  }

  const { currentPage } = context
  const animationRef = useRef<HTMLDivElement>(null)

  const initialCircles = useMemo(() => {
    return [
      { x: 400, y: 200, xSpeed: 2.0, ySpeed: 3.4 },
      { x: 600, y: 400, xSpeed: -5.0, ySpeed: 5.0 },
      { x: 800, y: 600, xSpeed: 3.0, ySpeed: 6.0 },
      { x: 1000, y: 500, xSpeed: -1.0, ySpeed: 6.0 },
      { x: 900, y: 300, xSpeed: -2.0, ySpeed: 3.0 }
    ]
  }, [])

  const circleRadius = 100

  const [bounds] = useState({
    minX: (-10 * window.innerWidth) / 100 - circleRadius,
    minY: (-10 * window.innerHeight) / 100 - circleRadius,
    maxX: window.innerWidth + (10 * window.innerWidth) / 100 + circleRadius,
    maxY: window.innerHeight + (10 * window.innerHeight) / 100 + circleRadius
  })

  const isIOS = () => {
    return /iPad|iPhone|iPod|Mac/.test(navigator.platform)
  }

  const stdDevValue = isIOS() ? '0' : '15'

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ctx = gsap.context(() => {
      initialCircles.forEach((circle, index) => {
        const selector = `.circle${index + 1}`
        // Set the circles to they initial position on load
        gsap.set(selector, { x: circle.x, y: circle.y })

        // Animate each circle in a random pattern
        gsap.to(selector, {
          x: () => {
            if (circle.x - circleRadius < bounds.minX) {
              circle.x = bounds.minX + circleRadius + circle.xSpeed
              circle.xSpeed = Math.abs(circle.xSpeed)
            } else if (circle.x + circleRadius > bounds.maxX) {
              circle.x = bounds.maxX - circleRadius - circle.xSpeed
              circle.xSpeed = -Math.abs(circle.xSpeed)
            } else {
              circle.x = circle.x + circle.xSpeed
            }

            return circle.x
          },
          y: () => {
            if (circle.y - circleRadius < bounds.minY) {
              circle.y = bounds.minY + circleRadius + circle.ySpeed
              circle.ySpeed = Math.abs(circle.ySpeed)
            } else if (circle.y + circleRadius > bounds.maxY) {
              circle.y = bounds.maxY - circleRadius - circle.ySpeed
              circle.ySpeed = -Math.abs(circle.ySpeed)
            } else {
              circle.y = circle.y + circle.ySpeed
            }

            return circle.y
          },
          repeat: -1,
          duration: 0.5,
          repeatRefresh: true,
          repeatDelay: 0,
          ease: 'none'
        })
      })
    }, animationRef)
  }, [bounds.maxX, bounds.maxY, bounds.minX, bounds.minY, initialCircles])

  return (
    <div
      className={classNames('circle-background', {
        backgroundHidden: currentPage === 'videoProcessing'
      })}
      ref={animationRef}
    >
      <svg className="metasvg">
        <defs>
          <filter id="gooify" width="400%" x="-10%" height="400%" y="-150%">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation={stdDevValue}
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0
                     0 1 0 0 0
                     0 0 1 0 0
                     0 0 0 25 -10"
            />
          </filter>
          <linearGradient id="lavaGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#d96700" />
            <stop offset="100%" stopColor="#f69b25" />
          </linearGradient>
        </defs>
        <g filter="url(#gooify)">
          {initialCircles.map((circle, index) => (
            <circle
              key={index}
              className={`circle${index + 1}`}
              fill="url(#lavaGradient)"
              r={circleRadius}
            />
          ))}
        </g>
      </svg>
    </div>
  )
}
