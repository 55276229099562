import React, { useContext, useState, useRef, useEffect, useMemo } from 'react'
import './CameraDeniedPage.scss'
import InstructionsButton from '../../InstructionButton/InstructionButton'
import cameraDenied from '../../../assets/CameraDenied.svg'
import AppContext from '../../../AppContext'
import QRCodeStyling from 'qr-code-styling'

export const CameraDeniedPage = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error(
      'AppContext is undefined, ensure it is provided by a context provider'
    )
  }

  const { setDisplayCameraDeniedPage, openEnableCameraModal } = context
  const [displayQRCode, setDisplayQRCode] = useState<boolean>(false)
  const [qrAppended, setQrAppended] = useState<boolean>(false)

  const handleRetry = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
      setDisplayCameraDeniedPage(false)
    } catch (error) {
      setDisplayQRCode(true)
    }
  }

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 190,
        height: 190,
        type: 'svg',
        data: window.location.origin,
        qrOptions: {
          errorCorrectionLevel: "L"
        },
        dotsOptions: {
          color: '#FF7900',
          type: 'square'
        },
        backgroundOptions: {
          color: '#F5F0E4'
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 20
        }
      }),
    []
  )

  const QRCodeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (QRCodeRef.current && !qrAppended) {
      qrCode.append(QRCodeRef.current)
      setQrAppended(true)
    }
  }, [qrCode, QRCodeRef, qrAppended, displayQRCode])

  return (
    <div className="CameraDeniedPage">
      <div className="CameraDeniedPage--hero">
        <InstructionsButton />
        <h1>How do you pulp it?</h1>
      </div>

      <div className="CameraDeniedPage--content">
        <h1>Oops!</h1>
        <p>
          {displayQRCode
            ? 'It seems we couldn’t detect a camera on your device'
            : 'To experience this pulpy adventure, we need access to your camera'}
        </p>
        {displayQRCode ? (
          <>
            <div className="QRCode" ref={QRCodeRef}></div>
            <p className="QRCode-description">
              Scan or visit the WEBURL on your mobile device to continue the
              pulpy adventure
            </p>
          </>
        ) : (
          <img
            src={cameraDenied}
            alt="camera denied"
            onClick={openEnableCameraModal}
          />
        )}

        {!displayQRCode && <button onClick={handleRetry}>Retry</button>}
      </div>
    </div>
  )
}
