import React, { useCallback, useContext, useEffect, useState } from 'react'
import './FaceMorphScene.scss'
import { AframeScene } from './AframeScene'
import faceMorphHtml from '!html-loader!./FaceMorph.html' // eslint-disable-line import/no-webpack-loader-syntax
import { gltfMorph } from './components/gltfMorph'
import { animateFace } from './components/animateFace'
import AppContext from '../../AppContext'

const components = [
  {name: 'gltf-morph', val: gltfMorph},
  {name: 'animate-face', val: animateFace}
]

window.onload = () => {
  const xrExtrasScript = document.createElement('script') as unknown as HTMLScriptElement
  const xr8WallScript = document.createElement('script') as unknown as HTMLScriptElement
  xrExtrasScript.src = '//cdn.8thwall.com/web/xrextras/xrextras.js'
  xr8WallScript.src = '//apps.8thwall.com/xrweb?appKey=klZ7bFcrSUip6wAnvqMVBulkF3tLHII3QgFxzcxoeGGPGiOkYXGiIQyUSwNvynt69AWRxL'

  document.body.append(xrExtrasScript, xr8WallScript)
}

export const FaceMorphScene = () => {
  const [sceneReady, setSceneReady] = useState(false)
  const context = useContext(AppContext)
  if (!context) throw new Error('FaceMorphScene requires AppContext!')

  const handleLoaded = useCallback(() => {
    setSceneReady(true)
  }, [setSceneReady])

  useEffect(() => {
    if (sceneReady) {
      const model = document.getElementById('morphTargetModel')
      if (!model) return

      const { targets, values } = Object.entries(context.expression.morph).reduce((obj, [target, value]) => {
        obj.targets.push(target)
        obj.values.push(value)
        return obj
      }, {targets: [] as string[], values: [] as number[]})

      model.setAttribute('gltf-morph', `targets: ${targets.join(',')}; values: ${values.join(',')}`)
    }
  }, [context.expression, sceneReady])
  
  return (
    <AframeScene
      sceneHtml={faceMorphHtml}
      components={components}
      onLoaded={handleLoaded}
    />
  )
}