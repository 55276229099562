import React, { useContext } from 'react'
import './EnableCameraModal.scss'
import AppContext from '../../../AppContext'

export const EnableCameraModal = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const { closeModal } = context

  return (
    <div className="EnableCameraModal">
      <p>How to Enable Camera Access:</p>
      <ol>
        <li>
          1. Click the tune icon <span className="tune-icon" /> in your
          browser's address bar.
        </li>
        <li>
          2. Find "Camera" <span className="camera-icon" /> and select "Allow."
        </li>
      </ol>
      <button onClick={closeModal}>Close</button>
    </div>
  )
}
