import React from 'react'
import './PlayButton.scss'

interface PlayButtonProps {
  videoRef: React.RefObject<any>
  videoPlaying: boolean
  setVideoPlaying: (isPlaying: boolean) => void
}

export const PlayButton = ({
  videoRef,
  videoPlaying,
  setVideoPlaying
}: PlayButtonProps) => {
  return (
    <div
      className={`PlayButton ${!videoPlaying ? 'play' : 'pause'}`}
      onClick={() => {
        if (!videoPlaying) {
          videoRef.current.play()
          setVideoPlaying(true)
        } else {
          videoRef.current.pause()
          setVideoPlaying(false)
        }
      }}
    ></div>
  )
}
