import { useEffect, useState } from 'react'

export function useIsLandscape(): boolean {
  const [isLandscape, setIsLandscape] = useState<boolean>(
    window.innerWidth > window.innerHeight ||
    Math.abs(window.orientation) === 90
  )

  useEffect(() => {
    const handleOrientationChange = () => {
      const isLandscapeMode = window.innerWidth > window.innerHeight ||
        Math.abs(window.orientation) === 90
      setIsLandscape(isLandscapeMode)
    }

    window.addEventListener('resize', handleOrientationChange)
    window.addEventListener('orientationchange', handleOrientationChange)
    handleOrientationChange()

    return () => {
      window.removeEventListener('resize', handleOrientationChange)
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  return isLandscape
}

