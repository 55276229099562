import React, { useContext } from 'react'
import { getVerb } from '../../utils/video/verbs'
import './VerbPage.scss'
import AppContext, { DataComponents } from '../../AppContext'
import InstructionsButton from '../InstructionButton/InstructionButton'
import { trackGeneratePulpyFace, trackWordRegenerate } from '../../api/cdsTracking'

export const VerbPage = ({data}: { data: DataComponents['verbPage'] }) => {
  const context = useContext(AppContext)
  if (!context) throw new Error('VerbPage requires AppContext.')

  const {
    username,
    verb,
    setVerb,
    setCurrentPage
  } = context

  const changeVerb = () => {
    const newVerb = getVerb()
    setVerb(newVerb)
    trackWordRegenerate()
  }

  const handleNext = () => {
    trackGeneratePulpyFace()
    setCurrentPage('imageCapture')
  }

  return (
    <div className='verb-page page'>
      <div className='container container-wrapper'>
        <InstructionsButton/>
        <p className='FormPage__subtitle mix-blend'>{data.subtitle}</p>
        <h2 className='verb-page__title mix-blend'>{username} Likes to</h2>
        <h1 className='verb-page__titleDefinition'>{verb.form1} it</h1>
        <dt>Definition</dt>
        <dd>{verb.definition}</dd>
        <button className='strokeButton' onClick={changeVerb}>{data.buttonReg}</button>
        <button className='blueButton' onClick={handleNext}>{data.buttonNext}</button>
      </div>
    </div>
  )
}
