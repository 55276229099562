import { getCdsUserIdentifier } from "../utils/storageUtils"
import { CDSGeoData, getCachedGeoData } from "./geoIp"

export interface SaveUserRequestData {
  phoneNumber: string
  pin: number
  name: string
  dob?: string
  consent: boolean
  cdsUserIdentifier?: string
  geoData?: CDSGeoData
}

export interface SaveUserResponse {
  status: 'success'
}

export interface SaveUserError {
  status: 'invalid request body' | 'invalid phoneNumber' | 'invalid pin' | 'server error'
}

export const getSaveUserUrl = () => {
  return `${process.env.REACT_APP_API_BASE_URL}/save-user`
}

export const saveUser = async (data: SaveUserRequestData): Promise<SaveUserResponse> => {
  data.cdsUserIdentifier = getCdsUserIdentifier()
  data.geoData = await getCachedGeoData()

  return fetch(getSaveUserUrl(), {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      'Content-Type': "application/json",
    }),
    body: JSON.stringify(data)
  }).then((response) => response.json())
}