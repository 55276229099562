import React from 'react'
import './RotatePhone.scss'
import rotatePhone from '../../assets/rotatePhone.svg'

export const RotatePhone = () => {
  return (
    <div className="RotatePhone">
      <img src={rotatePhone} alt="Rotate phone" />
      <h1>please rotate the screen back to portrait</h1>
    </div>
  )
}
