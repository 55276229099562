export enum MorphTargets {
  cheekBonesExpand = 'cheekBonesExpand',
  cheeksExpand = 'cheeksExpand',
  cheeksShrink = 'cheeksShrink',
  eyesExpand = 'eyesExpand',
  eyesShrink = 'eyesShrink',
  eyesPop = 'eyesPop',
  lipsExpand = 'lipsExpand',
  lipsPurse = 'lipsPurse',
  lipsPucker = 'lipsPucker',
  lipsStretch = 'lipsStretch',
  lipsSmile = 'lipsSmile',
  lipsFrown = 'lipsFrown',
  noseExpand = 'noseExpand',
  noseShrink = 'noseShrink',
  foreheadExpand = 'foreheadExpand',
  headExpand = 'headExpand',
  headShrink = 'headShrink',
  browsSad = 'browsSad',
  browsAngry = 'browsAngry',
  browsSurprised = 'browsSurprised',
  leftBlink = 'leftBlink',
  rightBlink = 'rightBlink',
  leftBrowUp = 'leftBrowUp',
  rightBrowUp = 'rightBrowUp',
  leftSmirk = 'leftSmirk',
  rightSmirk = 'rightSmirk',
  leftLipPucker = 'leftLipPucker',
  rightLipPucker = 'rightLipPucker',
}

export interface Expression {
  name: string
  displayName: string
  morph: {
    [K in MorphTargets]?: number
  }
}

export const expressions = [
  {
    name: 'none',
    displayName: 'None',
    morph: {}
  },
  {
    name: 'face5a',
    displayName: 'Face 5',
    morph: {
      lipsPucker: 0.88,
      lipsExpand: 0.69,
      lipsSmile: 0.18,
      eyesExpand: 0.26,
      cheeksShrink: 0.27,
      browsSurprised: 1,
    }
  },
  {
    name: 'face17',
    displayName: 'Face 17',
    morph: {
      noseShrink: 0.48,
      lipsPucker: 0.5,
      eyesShrink: 0.24
    }
  },
  {
    name: 'face2b',
    displayName: 'Face 2',
    morph: {
      cheeksExpand: 0.56 * 0.75,
      eyesExpand: 0.18 * 0.75,
      lipsExpand: 0.73 * 0.75,
      lipsPucker: 0.76 * 0.75,
      noseShrink: 0.22 * 0.75,
      browsSurprised: 1 * 0.75,
    }
  },
]

export const getRandomExpression = (): [Expression, number] => {
  const index = Math.floor(Math.random() * expressions.length)
  return [expressions[index], index]
}

export const expressionsByName = Object.fromEntries(expressions.map(expression => [expression.name, expression]))

export const getExpressionByName = (name: string) => {
  return expressionsByName[name]
}
