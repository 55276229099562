import React, { useCallback, useContext, useState } from 'react'
import AppContext, { DataComponents } from '../../AppContext'
import './MainPage.scss'
import { useIsMobile } from '../../utils/viewport'
import InstructionsButton from '../InstructionButton/InstructionButton'
import { hasValidStoredInfo } from '../../utils/storageUtils'
import { LoadingIcon } from '../LoadingIcon/LoadingIcon'
import { UnsupportedBrowsers } from '../UnsupportedBrowsers/UnsupportedBrowsers'
import { useIsBrowserSupported } from '../../utils/browserSupported'

export const MainPage = ({ data }: { data: DataComponents['mainPage'] }) => {
  const { setCurrentPage, login } = useContext(AppContext) ?? {}

  if (!setCurrentPage || !login) {
    throw new Error('AppContext must be used within a Provider')
  }

  const isMobile = useIsMobile()
  const isBrowserSupported = useIsBrowserSupported()
  const [isLoading, setIsLoading] = useState(false)

  const handleNextPage = useCallback(async () => {
    setIsLoading(true)
    const userData = await hasValidStoredInfo()

    if (userData) {
      login(userData)
      setCurrentPage('form')
    } else {
      setCurrentPage('contact')
    }
    setIsLoading(false)
  }, [login, setCurrentPage])

  type CardImageProps = {
    src: string
    alt: string
  }

  const CardImage = ({ src, alt }: CardImageProps) => (
    <div className='main-page__cards'>
      <img className='main-page__cards-image' src={src} alt={alt} />
    </div>
  )

  return (
    <div className='main-page page'>
      <div className='container container-wrapper'>
        <InstructionsButton />
        <div className='main-page__wrapper'>
          <div className='main-page__left'>
            <h1 className='main-page__title mix-blend'>{data.title}</h1>
            <p className='main-page__subtitle'>{data.subtitle}</p>
            {isMobile && <CardImage src={data.cardsMobile.src} alt={data.cardsMobile.alt} />}
            <p className='main-page__description'>{data.paragraph}</p>
            <button
              className='main-page__button-next blackButton'
              onClick={handleNextPage}
              disabled={isLoading}
            >
              {isLoading ? <LoadingIcon /> : data.buttonText}
            </button>
            {isMobile && !isBrowserSupported && <UnsupportedBrowsers />}
          </div>
          {!isMobile && <CardImage src={data.cardsDesktop.src} alt={data.cardsDesktop.alt} />}
        </div>
      </div>
    </div>
  )
}
