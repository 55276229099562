import React, { useContext, useEffect, useState } from 'react'
import './App.scss'
import { Modal } from './components/Modal/Modal'
import { ModalFlow } from './components/ModalFlow/ModalFlow'
import { MainPage } from './components/MainPage/MainPage'
import { LoadingPage } from './components/LoadingPage/LoadingPage'
import { ContactPage } from './components/ContactPage/ContactPage'
import AppContext, { DataAppContext } from './AppContext'
import { Footer } from './components/Footer/Footer'
import { FormPage } from './components/FormPage/FormPage'
import { VideoProcessingPage } from './components/VideoProcessingPage/VideoProcessingPage'
import { VideoResultPage } from './components/VideoResultPage/VideoResultPage'
import { ImageCapturePage } from './components/ImageCapturePage/ImageCapturePage'
import { ImageConfirmPage } from './components/ImageCapturePage/ImageConfirmPage/ImageConfirmPage'
import { VerbPage } from './components/VerbPage/VerbPage'
import { CircleBackground } from './components/CircleBackground/CircleBackground'
import { useIsMobile } from './utils/viewport'
import { useIsLandscape } from './utils/orientation'
import { RotatePhone } from './components/RotatePhone/RotatePhone'
import classNames from 'classnames'

function App() {
  const context = useContext(AppContext) as DataAppContext
  const [isLoading] = useState(false)
  const isMobile = useIsMobile()
  const isLandscape = useIsLandscape()
  const [screenHeight, setScreenHeight] = useState(0)

  if (!context) {
    throw new Error('App must be used within AppProvider')
  }

  const {
    data,
    currentPage,
    setCurrentPage,
    displayImageConfirmPage,
    displayCameraDeniedPage
  } = context

  useEffect(() => {
    window.history.pushState({ page: currentPage }, '', currentPage)
    window.scrollTo(0, 0)
  }, [currentPage])

  useEffect(() => {
    const handlePopstate = (event: PopStateEvent) => {
      if (event.state && event.state.page) {
        setCurrentPage(event.state.page)
      }
    }
    window.addEventListener('popstate', handlePopstate)
    return () => window.removeEventListener('popstate', handlePopstate)
  }, [setCurrentPage])

  useEffect(() => {
    if (currentPage === 'imageCapture') {
      const sceneElement = document.getElementById('aframeScene')

      if (isMobile && isLandscape) {
        sceneElement!.style.visibility = 'hidden'
      } else {
        if (!displayImageConfirmPage && !displayCameraDeniedPage) {
          sceneElement!.style.visibility = 'visible'
        }
      }
    }
  }, [
    isMobile,
    isLandscape,
    currentPage,
    displayCameraDeniedPage,
    displayImageConfirmPage
  ])

  const pages: any = {
    main: <MainPage data={data.mainPage} />,
    contact: (
      <ContactPage phoneNumberData={data.contactPage} otpData={data.otpPage} />
    ),
    form: <FormPage data={data.formPage} />,
    verb: <VerbPage data={data.verbPage} />,
    imageCapture: <ImageCapturePage data={data.imageCapturePage} />,
    imageConfirm: <ImageConfirmPage data={data.imageConfirmPage} />,
    videoProcessing: <VideoProcessingPage data={data.videoProcessingPage} />,
    videoResult: <VideoResultPage data={data.videoResultPage} />
  }

  const CurrentPageComponent = isLoading ? (
    <LoadingPage />
  ) : (
    pages[currentPage] || pages.main
  )

  useEffect(() => {
    setScreenHeight(window.innerHeight)
    const resize = () => {
      setScreenHeight(window.innerHeight)
    }
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [setScreenHeight])

  return (
    <div className="App">
      <CircleBackground />
      <div
        className={classNames('PageWithFooter', currentPage, {
          hidePage: isLandscape && isMobile
        })}
        style={{
          height:
            currentPage === 'imageCapture' && isMobile && !isLandscape
              ? `${screenHeight}px`
              : ''
        }}
      >
        {CurrentPageComponent}
        <Footer data={data.footer} />
      </div>

      {isMobile && isLandscape && <RotatePhone />}

      <Modal>
        <ModalFlow />
      </Modal>
    </div>
  )
}

export default App
