import { createUserCard } from "./createUserCard"
import { findLandmarks } from "./mediaPipeUtils"
import { Verb } from "./verbs"
import { validateImage } from "../../api/validateImage"
import { errorMessagesByStatus } from "./errorMessagesByStatus"
import { generateVideo } from "../../api/generateVideo"

export const createUserVideo = async (
  username: string,
  verb: Verb,
  userImage: HTMLImageElement,
  setError: (errorInfo: string) => void,
  setCurrentPage: (page: string) => void,
  setOutputVideo: (outputVideo: string) => void
): Promise<void> => {
  const userLandmarks = await findLandmarks(userImage)

  if (!userLandmarks) {
    setError('Please use an image that contains a face!')
    return
  }

  try {
    const validateImageResponse = await validateImage({ imageData: userImage.src })

    if (validateImageResponse.status !== 'success') {
      const error = errorMessagesByStatus[validateImageResponse.status]
      setError(error)
      return
    }
  } catch (error) {
    console.error(error)
    setError('The image you uploaded does not meet our content moderation requirements. Please remove and try again.')
    return
  }

  // Now that we've finished validating the input, we can start processing.
  setCurrentPage('videoProcessing')

  // Create user card
  const userCard = await createUserCard(username, verb, userImage, userLandmarks)

  generateVideo({ imageData: userCard.toDataURL('image/jpeg') })
    .then((videoData) => {
      setOutputVideo(videoData)
      setCurrentPage('videoResult')
    })
    .catch((error) => {
      console.error('Problem generating video: ', error)
      setError('There was a problem generating your video. Please try again.')
      setCurrentPage('imageConfirm')
    })
}
