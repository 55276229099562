import { Component } from "aframe"
import { Mesh } from "three"

export interface GltfMorphComponentData {
  targets: string[]
  values: string[]
}

export interface GltfMorphComponent extends Component<GltfMorphComponentData> {
  setMorph: (targets: string[], values?: string[]) => void
}

// @ts-ignore
export const gltfMorph: GltfMorphComponent = {
  multiple: true,
  schema: {
    targets: { type: 'array', default: [] as string[] },
    values: { type: 'array', default: [] as string[] },
  },
  init() {
    this.setMorph = this.setMorph.bind(this)
    this.el.addEventListener('object3dset', () => {
      this.setMorph(this.data.targets, this.data.values)
    })
  },
  update(oldData) {
    if (oldData.targets) {
      this.setMorph(oldData.targets)
    }
    this.setMorph(this.data.targets, this.data.values)
  },
  remove() {
    this.setMorph(this.data.targets)
  },
  setMorph(targets, values) {
    if (!values) {
      values = new Array(targets.length).fill(0)
    }

    const mesh = this.el.object3D as Mesh
    mesh.traverse((child) => {
      const o = child as Mesh
      if (o.morphTargetInfluences && o.userData.targetNames) {
        const morphTargetInfluences = o.morphTargetInfluences

        targets.forEach((target, index) => {
          const pos = o.userData.targetNames.indexOf(target)
          const valueStr = values?.[index]
          const value = valueStr ? parseFloat(valueStr) : 0
          morphTargetInfluences[pos] = value
        })
      }
    })
  }
}
