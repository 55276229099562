import React, { useContext } from 'react'
import * as Yup from 'yup'
import AppContext, { DataComponents } from '../../AppContext'
import InstructionsButton from '../InstructionButton/InstructionButton'
import './FormPage.scss'
import Filter from 'bad-words'
import { swearsList } from '../../utils/swearsList'
import { Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import { saveUser } from '../../api/saveUser'

import { TextInput } from './TextInput/TextInput'
import { DateInput } from './DateInput/DateInput'
import { Checkbox } from './Checkbox/Checkbox'

const filter = new Filter()
filter.addWords(...swearsList.list)

export const FormPage = ({ data }: { data: DataComponents['formPage'] }) => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('FormPage must be used within an AppProvider')
  }

  const {
    setCurrentPage,
    login,
    username,
    dob,
    consent,
    contactInfo,
    otp,
    isLoggedIn
  } = context

  const validationSchema = Yup.object({
    username: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Only letters are allowed for this field')
      .required('*required')
      .test(
        'profane test',
        'The name you entered does not meet our content moderation requirements. Please try again.',
        (value) => !filter.isProfane(value)
      ),
    dob: Yup.date()
      .nullable()
      .test('is-18', 'Please enter a valid date', function (value) {
        if (!value) return true
        const age = Math.floor(
          DateTime.now().diff(DateTime.fromJSDate(value), 'years').years
        )
        return age >= 18
      }),
    consent: Yup.boolean().oneOf(
      [true],
      'To proceed, please accept usage conditions'
    )
  })

  return (
    <Formik
      initialValues={{ username: username, dob: dob, consent: consent }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        saveUser({
          phoneNumber: contactInfo,
          pin: parseInt(otp),
          name: values.username,
          dob: values.dob,
          consent: values.consent
        })
        login({
          name: values.username,
          dob: values.dob,
          consent: values.consent
        })
        setCurrentPage('verb')
        setSubmitting(false)
      }}
    >
      {() => {
        return (
          <div className="FormPage page">
            <div className="container container-wrapper">
              <InstructionsButton/>
              <div className="FormPage__wrapper">
                <p className="FormPage__subtitle mix-blend">{data.subtitle}</p>
                <Form>
                  <TextInput label={data.title} name="username"/>
                  <DateInput label="Date of Birth (Optional)" name="dob" isLoggedIn={isLoggedIn}/>
                  <Checkbox
                    name="consent"
                    isLoggedIn={isLoggedIn}
                    className="your-checkbox-classname"
                  />
                  <button className="blackButton" type="submit">
                    Continue
                  </button>
                </Form>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}
