interface GeoIpData {
  query: string,
  status: string,
  country: string,
  countryCode: string,
  region: string,
  regionName: string,
  city: string,
  zip: string,
  lat: number,
  lon: number,
  timezone: string,
  isp: string,
  org: string,
  as: string
}

export interface CDSGeoData {
  ip_address: string
  geo_country_code: string
  geo_state_province_code: string
  geo_city_name: string
  geo_postal_code: string
}

export const geoIpLookup = async (): Promise<CDSGeoData> => {
  const response = await fetch('https://pro.ip-api.com/json?key=La0kty9rENiQVUj')
  const geoIpData: GeoIpData = await response.json()

  return {
    ip_address: geoIpData.query,
    geo_country_code: geoIpData.countryCode,
    geo_state_province_code: geoIpData.region,
    geo_city_name: geoIpData.city,
    geo_postal_code: geoIpData.zip
  }
}

let cachedGeoIpData: CDSGeoData
export const getCachedGeoData = async (): Promise<CDSGeoData> => {
  if (cachedGeoIpData !== undefined) {
    return cachedGeoIpData
  }

  cachedGeoIpData = await geoIpLookup()
  return cachedGeoIpData
}