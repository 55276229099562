import { imageFromDataUrl } from "./video/fileUtils"

export const flipImageData = async (dataUrl: string): Promise<string> => {
  const img = await imageFromDataUrl(dataUrl)
  const canvas = document.createElement('canvas') as unknown as HTMLCanvasElement
  canvas.width = img.width
  canvas.height = img.height

  const ctx = canvas.getContext('2d')

  if (!ctx) {
    console.error('Problem creating context for image flipping!')
    return ''
  }

  ctx.translate(img.width, 0)
  ctx.scale(-1,1)
  ctx.drawImage(img, 0, 0)
  ctx.setTransform(1, 0, 0, 1, 0, 0)

  return canvas.toDataURL('image/jpeg')
}