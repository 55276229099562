import React, { ChangeEvent, useCallback, useContext, useState } from "react"
import './EditExpression.scss'
import AppContext from "../../../AppContext"
import { MorphTargets, expressions } from "../../../utils/video/expressions"
import { wrappingModulo } from "../../../utils/mathUtils"

const morphTargets = Object.values(MorphTargets)
export const EditExpression = () => {
  const [morphIndex, setMorphIndex] = useState(0)
  const [morphName, setMorphName] = useState(morphTargets[0])
  const [morphValue, setMorphValue] = useState(0)
  const context = useContext(AppContext)
  if (!context) throw new Error('EditExpression requires AppContext.')

  const {
    expression,
    setExpression
  } = context

  const handleReset = useCallback(() => {
    setMorphValue(0)
    setExpression(expressions[0])
  }, [setExpression])

  const handleSave = useCallback(() => {
    const simplifiedMorph = Object.fromEntries(Object.entries(expression.morph).filter(([key, value]) => value))
    console.log(simplifiedMorph)
    navigator.clipboard.writeText(Object.entries(simplifiedMorph).map(([key, val]) => `${key}: ${val}`).join('\n'))
  }, [expression])

  const changeMorph = useCallback((direction: number) => {
    const newIndex = wrappingModulo(morphIndex + direction, morphTargets.length)
    const newMorph = morphTargets[newIndex]
    setMorphIndex(newIndex)
    setMorphName(newMorph)
    setMorphValue(expression.morph[newMorph] || 0)
  }, [expression.morph, morphIndex])

  const handleValueChanged = useCallback((event: ChangeEvent) => {
    const target = event.target as HTMLInputElement
    const value = parseFloat(target.value)
    setMorphValue(value)
    setExpression({
      ...expression,
      morph: {
        ...expression.morph,
        [morphName]: value
      }
    })
  }, [expression, morphName, setExpression])

  return (
    <div className="EditExpression__wrapper">
      <button className="reset" onClick={handleReset}>Reset</button>
      <button className="save" onClick={handleSave}>Save</button>
      <button className="prev" onClick={() => changeMorph(-1)}>&lt;</button>
      <h2 className="title">{morphName}</h2>
      <button className="next" onClick={() => changeMorph(1)}>&gt;</button>
      <input
        className="valueInput"
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={morphValue}
        onChange={handleValueChanged}
      />
    </div>
  )
}
