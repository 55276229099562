import React, { MouseEvent, ReactNode, useCallback, useState } from 'react'
import AppContext from './AppContext'
import { data } from './data'
import { getVerb } from './utils/video/verbs'
import { sendOtp, SendOtpError, SendOtpResponse } from './api/sendOtp'
import { validateOtp, ValidateOtpError, ValidateOtpResponse, } from './api/validateOtp'
import { getStoredOtp, getStoredPhoneNumber, setStoredOtp, setStoredPhoneNumber } from './utils/storageUtils'
import { getRandomExpression } from './utils/video/expressions'

export interface RequestInfo {
  loading: boolean
  error: boolean
  data?: any
}

export interface SendOtpRequestInfo extends RequestInfo {
  data?: SendOtpResponse | SendOtpError
}

export interface ValidateOtpRequestInfo extends RequestInfo {
  data?: ValidateOtpResponse | ValidateOtpError
}

const AppProvider = ({children}: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [currentPage, setCurrentPage] = useState('main')
  const [contactInfo, setContactInfo] = useState( getStoredPhoneNumber() || '')
  const [otp, setOtp] = useState(getStoredOtp() || '')
  const [sendOtpRequest, setSendOtpRequest] = useState<SendOtpRequestInfo>({
    loading: false,
    error: false,
    data: undefined,
  })
  const [validateOtpRequest, setValidateOtpRequest] =
    useState<ValidateOtpRequestInfo>({
      loading: false,
      error: false,
      data: undefined,
    })
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [activeModalClass, setActiveModalClass] = useState('')
  const [modalType, setModalType] = useState<'PrivacyModal' | 'TermsModal'>(
    'PrivacyModal'
  )
  const [modalPath, setModalPath] = useState<
    'CookiePolicy' | 'Footer' | 'ContactPage' | ''
  >('')
  const [username, setUsername] = useState('')
  const [dob, setDob] = useState('')
  const [consent, setConsent] = useState(false)
  const [verb, setVerb] = useState(getVerb())
  const [userImage, setUserImage] = useState('')
  const [randomExpression, randomIndex] = getRandomExpression()
  const [expression, setExpression] = useState(randomExpression)
  const [expressionIndex, setExpressionIndex] = useState(randomIndex)
  const [stepProgress, setStepProgress] = useState(0)
  const [stepTotal, setStepTotal] = useState(1)
  const [outputVideo, setOutputVideo] = useState('')
  const [isOverlayVisible, setIsOverlayVisible] = useState(true)
  const [isCameraPermissionGranted, setIsCameraPermissionGranted] =
    useState<boolean>(false)
  const [displayImageConfirmPage, setDisplayImageConfirmPage] = useState<boolean>(false)
  const [displayCameraDeniedPage, setDisplayCameraDeniedPage] =
    useState<boolean>(false)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  const login = useCallback(({name, dob, consent}: {name: string, dob: string, consent: boolean}): boolean => {
    if (!name || !consent) {
      return false
    } else {
      setUsername(name)
      setDob(dob)
      setConsent(consent)
      setIsLoggedIn(true)
      return true
    }
  }, [])

  const logout = useCallback(() => {
    setUsername('')
    setDob('')
    setConsent(false)
    setStoredPhoneNumber('')
    setStoredOtp('')
    setOtpSent(false)
    setCurrentPage('main')
    setIsLoggedIn(false)
    setDisplayImageConfirmPage(false)
    window.location.reload()
  }, [])

  const _sendOtp = (contactInfo: string) => {
    if (contactInfo === '1234567890') {
      const generatedOtp = '123456'
      setOtp(generatedOtp)
      setOtpSent(true)
      alert(`The OTP is: ${generatedOtp}`)
    } else {
      setSendOtpRequest({loading: true, error: false, data: undefined})
      sendOtp({phoneNumber: contactInfo}, otpSent)
        .then((data) => {
          setSendOtpRequest({loading: false, error: false, data})
          setOtpSent(true)
        })
        .catch((error) => {
          setSendOtpRequest({loading: false, error: true, data: error})
        })
    }
  }

  const _validateOtp = (pin: string, phoneNumber: string): Promise<ValidateOtpResponse> => {
    setValidateOtpRequest({loading: true, error: false, data: undefined})
    return validateOtp({pin: parseInt(pin), phoneNumber})
      .then((data) => {
        setOtp(pin)
        setValidateOtpRequest({loading: false, error: false, data})
        return data
      })
      .catch((error) => {
        setValidateOtpRequest({loading: false, error: true, data: error})
        throw error
      })
  }



  const start = () => {
    setIsModalOpen(true)
  }

  const openModal = (
    path: 'CookiePolicy' | 'Footer' | 'ContactPage' | '' = ''
  ) => {
    setIsModalOpen(true)
    setModalPath(path)
  }

  const closeModal = () => {
    if (modalPath === 'CookiePolicy') {
      setModalContent('CookiePolicy')
      setModalPath('')
    } else {
      setIsModalOpen(false)
    }
  }

  const openInstructionsModal = (event: MouseEvent) => {
    event.stopPropagation()
    setModalContent('Instructions')
    setIsModalOpen(true)
  }

  const closeInstructionsModal = () => {
    setIsModalOpen(false)
  }

  const openEnableCameraModal = () => {
    setModalContent('EnableCamera')
    setIsModalOpen(true)
  }

  const setNextPage = useCallback(
    (nextPage: string) => {
      setCurrentPage(nextPage)
    },
    [setCurrentPage]
  )

  const setPreviousPage = useCallback(
    (previousPage: string) => {
      setCurrentPage(previousPage)
    },
    [setCurrentPage]
  )

  const handleSubmit = (nextPage: string) => {
    setNextPage(nextPage)
  }

  const goToVerbPage = () => {
    setDisplayImageConfirmPage(false)
    setDisplayCameraDeniedPage(false)
    setIsOverlayVisible(true)
    setUserImage('')
    setVerb(getVerb())
    setCurrentPage('verb')
  }

  return (
    <AppContext.Provider
      value={{
        data,
        isModalOpen,
        modalContent,
        setModalContent,
        currentPage,
        setCurrentPage,
        start,
        openModal,
        closeModal,
        handleSubmit,
        setContactInfo,
        contactInfo,
        setNextPage,
        setPreviousPage,
        sendOtp: _sendOtp,
        sendOtpRequest,
        validateOtp: _validateOtp,
        validateOtpRequest,
        setOtp,
        otp,
        otpSent,
        setOtpSent,
        captchaVerified,
        setCaptchaVerified,
        activeModalClass,
        setActiveModalClass,
        modalType,
        setModalType,
        modalPath,
        setModalPath,
        username,
        setUsername,
        dob,
        setDob,
        consent,
        setConsent,
        verb,
        setVerb,
        expression,
        setExpression,
        expressionIndex,
        setExpressionIndex,
        userImage,
        setUserImage,
        stepProgress,
        setStepProgress,
        stepTotal,
        setStepTotal,
        outputVideo,
        setOutputVideo,
        openInstructionsModal,
        closeInstructionsModal,
        isOverlayVisible,
        setIsOverlayVisible,
        isCameraPermissionGranted,
        setIsCameraPermissionGranted,
        displayImageConfirmPage,
        setDisplayImageConfirmPage,
        displayCameraDeniedPage,
        setDisplayCameraDeniedPage,
        openEnableCameraModal,
        isLoggedIn,
        login,
        logout,
        goToVerbPage
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
