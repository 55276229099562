import React, { useContext, useEffect, useState } from 'react'
import './PhoneNumberForm.scss'
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { AppContext, DataComponents } from '../../../AppContext'
import { setStoredPhoneNumber } from '../../../utils/storageUtils'
import { LoadingIcon } from '../../LoadingIcon/LoadingIcon'
import { phone } from 'phone'

const validationSchema = Yup.object({
  contact: Yup.string()
    .required('Please enter a contact number')
    .test('isValidNumber', 'Please enter a valid number', value =>
      phone(value).isValid
    ),
})

export const PhoneNumberForm = ({ data }: { data: DataComponents['contactPage'] }) => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const { setContactInfo, sendOtpRequest, otpSent } = context
  const [canResendOtp, setCanResendOtp] = useState(true)
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined

    if (otpSent && !canResendOtp) {
      setTimeLeft(10)
      intervalId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [otpSent, canResendOtp])

  useEffect(() => {
    if (timeLeft === 0 && !canResendOtp) {
      setCanResendOtp(true)
    }
  }, [timeLeft])

  const handleSubmit = (contact: string, setSubmitting: (isSubmitting: boolean) => void) => {
    setStoredPhoneNumber(contact)
    setContactInfo(contact)

    if (contact === '1234567890') {
      context.setOtp('123456')
      context.setOtpSent(true)
    } else {
      context.sendOtp(contact)
    }

    setSubmitting(false)
    setCanResendOtp(false)
  }

  return (
    <Formik
      initialValues={{ contact: '' }}
      validationSchema={validationSchema}
      onSubmit={({ contact }, { setSubmitting }) => handleSubmit(contact, setSubmitting)}
    >
      {({ setFieldValue, errors, touched }: FormikProps<{ contact: string }>) => (
        <div className='phoneNumber-form'>
          <h2 className='contact-page__title'>{data.deliveryTitle}</h2>
          <p className='contact-page__subtitle'>{data.deliveryDescription}</p>
          <Form>
            <div className='contact-page__inputWrapper'>
              <div className='contact-page__contactLabel'>{data.phoneSubtitle}</div>
              <Field
                className='contact-page__contactField'
                type='text'
                name='contact'
                placeholder={data.phonePlaceholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let inputValue = e.target.value.trim()
                  const isProduction = process.env.REACT_APP_ENV === 'external-prod' || process.env.REACT_APP_ENV === 'external-uat'
                  let cleanInput = inputValue.replace(/[^\d]/g, '')

                  if (isProduction && !cleanInput.startsWith('91')) {
                    cleanInput = `91${cleanInput}`
                  }

                  inputValue = `+${cleanInput}`

                  setFieldValue('contact', inputValue)
                }}

              />
            </div>
            {touched.contact && errors.contact && (
              <div className='error-wrapper'>
                <ErrorMessage name='contact' component='div' className='error'/>
              </div>
            )}
            <button
              type='submit'
              className='submit blackButton'
              disabled={sendOtpRequest.loading || (otpSent && !canResendOtp)}
            >
              {sendOtpRequest.loading ? (
                <LoadingIcon/>
              ) : !otpSent ? (
                'Send OTP'
              ) : (
                `Resend OTP ${timeLeft > 0 ? `(${timeLeft})` : ''}`
              )}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  )
}
