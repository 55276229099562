import { getCdsUserIdentifier } from "../utils/storageUtils";
import { CDSData } from "./CDSTypes";
import { getCachedGeoData } from "./geoIp";

declare global {
  interface Window {
    cdsTrackingClientId: string
    cds_pixel: (clientId: string, data: CDSData) => void
    cdsPixelLoaded: Promise<void>
  }
}

export const cdsSend = async (data: CDSData) => {
  await window.cdsPixelLoaded

  const userIdentifier = getCdsUserIdentifier()
  if (!userIdentifier) {
    console.error('Can not send CDS event, no CDS user identifier found')
    return
  }

  const fullData: CDSData = {
    brand_name: 'MMPO',
    ...data
  }
  console.log('Sending data to CDS:', window.cdsTrackingClientId, fullData)
  window.cds_pixel(window.cdsTrackingClientId, fullData)
}

export const trackClick = (eventSubType: string, data?: CDSData) => {
  cdsSend({
    event_type: 'CLICK',
    event_sub_type: eventSubType,
    ...data
  })
}

export const trackHomepageLoad = async () => {
  const geoData = await getCachedGeoData()

  cdsSend({
    event_type: 'PAGE_VIEW',
    event_sub_type: 'Homepage_Load',
    ...geoData
  })
}

export const trackWordRegenerate = trackClick.bind(this, 'Word_Regenerate')

export const trackGeneratePulpyFace = trackClick.bind(this, 'Generate_PulpyFace')

export const trackSubmitImage = trackClick.bind(this, 'Submit_Picture')

export const trackVideoShare = trackClick.bind(this, 'Video_Share')

export const trackVideoDownload = trackClick.bind(this, 'Video_Download')
