import { getExpressionByName } from "./expressions"

export interface Verb {
  /** First person form: I verb it */
  form1: string
  /** Third person form: He / She verbs it */
  form3: string
  definition: string
  expressions: string[]
}

export const verbs: Verb[] = [
  {
    form1: "slurptitious",
    form3: "slurptitiouses",
    definition: "Drinking sneakily or on the sly.",
    expressions: [
      "face2c"
    ]
  },
  {
    form1: "gulpsome",
    form3: "gulpsomes",
    definition: "A large and satisfying gulp.",
    expressions: [
      "face11"
    ]
  },
  {
    form1: "sipserene",
    form3: "sipserenes",
    definition: "A perfectly calm and soothing sip.",
    expressions: [
      "face2a"
    ]
  },
  {
    form1: "swigswank",
    form3: "swigswanks",
    definition: "Taking a posh and stylish sip.",
    expressions: [
      "face17"
    ]
  },
  {
    form1: "sipstir",
    form3: "sipstirs",
    definition: "Mixing a drink in one’s mouth with every sip.",
    expressions: [
      "face6a",
      "face11"
    ]
  },
  {
    form1: "drinkdrift",
    form3: "drinkdrifts",
    definition: "Getting lost in thoughts while drinking.",
    expressions: [
      "face9b"
    ]
  },
  {
    form1: "chugchime",
    form3: "chugchimes",
    definition: "Celebrating a successful chug.",
    expressions: [
      "face13"
    ]
  },
  {
    form1: "swillswell",
    form3: "swillswells",
    definition: "The rise of satisfaction after a good drink.",
    expressions: [
      "face15"
    ]
  },
  {
    form1: "sipshadow",
    form3: "sipshadows",
    definition: "The elusive perfect sip that one’s always chasing.",
    expressions: [
      "face15"
    ]
  },
  {
    form1: "guzzlegrin",
    form3: "guzzlegrins",
    definition: "The satisfied smile after a good guzzle.",
    expressions: [
      "face13"
    ]
  },
  {
    form1: "bottlebliss",
    form3: "bottleblisses",
    definition: "The joy of finding a rare or desired bottle of Minute Maid Pulpy.",
    expressions: [
      "face11"
    ]
  },
  {
    form1: "sipsoothe",
    form3: "sipsoothes",
    definition: "A drink that calms the nerves.",
    expressions: [
      "face6a"
    ]
  },
  {
    form1: "orangeswoon",
    form3: "orangeswoons",
    definition: "Overwhelmed by the taste of a superb citrus-y drink.",
    expressions: [
      "face12"
    ]
  },
  {
    form1: "chugchuckle",
    form3: "chugchuckles",
    definition: "Laughing after a surprising chug.",
    expressions: [
      "face14"
    ]
  },
  {
    form1: "bottlebask",
    form3: "bottlebasks",
    definition: "Relaxing while savoring a drink.",
    expressions: [
      "face9b"
    ]
  },
  {
    form1: "guzzleglint",
    form3: "guzzleglints",
    definition: "The gleam in one’s eye after a great gulp.",
    expressions: [
      "face5a"
    ]
  },
  {
    form1: "jugjubilee",
    form3: "jugjubilees",
    definition: "The celebration of a freshly filled Minute Maid Pulpy.",
    expressions: [
      "face5c"
    ]
  },
  {
    form1: "citruvive",
    form3: "citruvives",
    definition: "To be revived by the zestiness of the pulpy drink.",
    expressions: [
      "face2a"
    ]
  },
  {
    form1: "sipulgence",
    form3: "sipulgences",
    definition: "The luxury of savoring each pulpy sip.",
    expressions: [
      "face14"
    ]
  },
  {
    form1: "pulpease",
    form3: "pulpeases",
    definition: "The contentment one feels with the perfect pulp-to-juice ratio.",
    expressions: [
      "face15",
      "face19"
    ]
  },
  {
    form1: "zestquench",
    form3: "zestquenchs",
    definition: "Thoroughly satisfied by the zesty pulpiness of the drink.",
    expressions: [
      "face14"
    ]
  },
  {
    form1: "pulpplash",
    form3: "pulpplashs",
    definition: "The delightful splash of juice and pulp on the tongue.",
    expressions: [
      "face6a"
    ]
  },
  {
    form1: "zestzen",
    form3: "zestzens",
    definition: "Finding one's calm in the rich texture of pulpy juice.",
    expressions: [
      "face2c"
    ]
  },
  {
    form1: "citrusway",
    form3: "citrusways",
    definition: "The gentle sway one does when savoring the juicy goodness.",
    expressions: [
      "face5c"
    ]
  },
  {
    form1: "oranoodle",
    form3: "oranoodles",
    definition: "The act of playfully swishing the juice in one's mouth.",
    expressions: [
      "face5a"
    ]
  },
  {
    form1: "zestnest",
    form3: "zestnests",
    definition: "The cozy feeling of contentment after savoring the orangey pulp.",
    expressions: [
      "face19"
    ]
  },
  {
    form1: "pulpurse",
    form3: "pulpurses",
    definition: "The slight pucker of the lips in anticipation of the pulp.",
    expressions: [
      "face19"
    ]
  }
]

export const getVerb = () => {
  return verbs[Math.floor(Math.random() * verbs.length)]
}

export const getExpression = (verb: Verb) => {
  return getExpressionByName(verb.expressions[Math.floor(Math.random() * verb.expressions.length)])
}

