import React, { useContext, useEffect, useState } from 'react'
import './ShareButton.scss'
import { useIsMobile } from '../../../utils/viewport'
import AppContext from '../../../AppContext'
import { trackVideoShare } from '../../../api/cdsTracking'

export const ShareButton = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('VideoResultPage must be used within an AppProvider')
  }

  const { outputVideo } = context
  const isMobile = useIsMobile()
  const [linkedCopied, setLinkedCopied] = useState(false)

  useEffect(() => {
    if (linkedCopied) {
      const timer = setTimeout(() => {
        setLinkedCopied(false)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [linkedCopied])

  const handleShare = async () => {
    trackVideoShare()
    try {
      if (isMobile) {
        await mobileShareHandler()
      } else {
        desktopShareHandler()
      }
    } catch (error) {
      console.error('Sharing error:', error)
    }
  }


  const mobileShareHandler = async () => {
    const urlToShare = window?.location?.origin
    let videoBlob = await fetch(outputVideo).then((response) => response.blob())
    let videoFile = new File([videoBlob], 'minuteMaidVideo.mp4', {
      type: 'video/mp4'
    })

    const shareObject = {
      text: `Hey I used this cool Minute Maid® Pulpy Orange experience to generate my video.\n${urlToShare}`,
      title:
        'Hey I used this cool Minute Maid® Pulpy Orange experience to generate my video.',
      files: [videoFile]
    }

    const canShare = navigator.canShare(shareObject) || false

    if (canShare) {
      navigator.share(shareObject)
    } else {
      window.navigator.clipboard.writeText(urlToShare)
    }
  }

  const desktopShareHandler = async () => {
    window.navigator.clipboard.writeText(window.location.hostname)
      .then(() => setLinkedCopied(true))
      .catch(err => console.error('Fail to copy:', err))
  }

  const buttonStyle = isMobile
    ? {
      buttonClass: 'ShareButton--mobile',
      buttonText: 'Share'
    } : {
      buttonClass: `ShareButton--desktop ${linkedCopied ? 'copied' : ''}`,
      buttonText: linkedCopied ? 'Link Copied!' : 'Share Experience'
    }

  return (
    <button
      className={buttonStyle.buttonClass}
      onClick={handleShare}
    >
      {buttonStyle.buttonText}
    </button>
  )
}
