import React, { useContext, useState } from 'react'
import './ImageConfirmPage.scss'
import AppContext, { DataComponents } from '../../../AppContext'
import { imageFromDataUrl } from '../../../utils/video/fileUtils'
import { createUserVideo } from '../../../utils/video/createUserVideo'
import { useIsMobile } from '../../../utils/viewport'
import InstructionsButton from '../../InstructionButton/InstructionButton'
import { LoadingIcon } from '../../LoadingIcon/LoadingIcon'
import classNames from 'classnames'
import { trackSubmitImage } from '../../../api/cdsTracking'
import { getVerb } from '../../../utils/video/verbs'
import backIcon from '../../../assets/backIcon.svg'

export const ImageConfirmPage = ({
                                   data
                                 }: {
  data: DataComponents['imageConfirmPage']
}) => {
  const [error, setError] = useState('')
  const context = useContext(AppContext)
  if (!context) throw new Error('ImageCaptureHud requires AppContext.')

  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState<boolean>(false)

  const {
    username,
    verb,
    userImage,
    setCurrentPage,
    setOutputVideo,
    setDisplayImageConfirmPage,
    goToVerbPage,
    setUserImage
  } = context

  const handleProcessVideo = async () => {
    if (!checked) {
      setError('Your consent is mandatory to continue with the experience.')
      return
    }

    setIsLoading(true)
    const img = await imageFromDataUrl(userImage)

    trackSubmitImage()
    createUserVideo(
      username,
      verb,
      img,
      setError,
      setCurrentPage,
      setOutputVideo
    ).finally(() => {
      setIsLoading(false)
    })
  }

  const handleBack = async () => {
    setDisplayImageConfirmPage(false)
    setUserImage('')
  }

  const ConsentCheckbox = () => (
    <div className='consent-checkbox-wrapper'>
      <input
        type='checkbox'
        id='imageConsentCheckbox'
        onInput={(e: any) => setChecked(e.target.checked)}
      />
      <label
        htmlFor='imageConsentCheckbox'
        className={classNames('consent-checkbox', {
          checkboxChecked: checked
        })}
        onClick={() => {
          setError('')
          setChecked((prevState) => !prevState)
        }}
      ></label>
      <p>
        I consent to the use of my image and likeness to Coca-Cola India
        Pvt.Ltd. for the purpose of creating my video using Artificial
        intelligence, and in any promotional material, advertising material or
        otherwise on any media for an unlimited period for the purpose of
        promoting this contest, without remuneration.
      </p>
    </div>
  )

  return (
    <>
      <button
        className='backButtonVerb'
        type="button"
        onClick={goToVerbPage}
      >
        <img src={backIcon} alt="go to verb page" />
      </button>
      <InstructionsButton/>
      {isMobile && error && <p className='Error'>{error}</p>}

      <div className='ImageConfirmPage'>
        <ConsentCheckbox/>
        <div className='ButtonsWrapper'>
          <button
            className='submit blackButton'
            onClick={handleProcessVideo}
            disabled={isLoading}
          >
            {!isLoading ? 'Submit' : <LoadingIcon/>}
          </button>
          <button className='RetakePhotoButton' onClick={handleBack}>
            Retake photo
          </button>
        </div>
        {!isMobile && error && <p className='Error'>{error}</p>}
        <img className='UserImage' src={userImage} alt='User screenshot'/>
      </div>
    </>
  )
}
