import cardsMobile from './assets/cardsMobile.png'
import cardsDesktop from './assets/cardsDesktop.png'
import guideImage from './assets/guide-upload.gif'
import iconClose from './assets/close.svg'
import playButton from './assets/play.svg'
import pauseButton from './assets/pause.svg'
import downloadButtonDesktop from './assets/download-desktop.svg'
import downloadButtonMobile from './assets/download-mobile.svg'
import square from './assets/square.png'
import arrowBtn from './assets/arrowBtn.svg'

export const data = {
  mainPage: {
    title: 'A Pulpy experience like never before',
    subtitle: 'Featuring Shraddha Kapoor and YOU',
    cardsMobile: {src: cardsMobile, alt: 'demo'},
    cardsDesktop: {src: cardsDesktop, alt: 'demo'},
    paragraph: 'Discover endless ways of pulping your Minute Maid® Pulpy Orange with Shraddha Kapoor and get a chance* to meet her!',
    buttonText: 'Enter Experience',
  },

  cookiePolicy: {
    title: 'Terms of the experience',
    message: 'I certify that I am at least 18 years old and have read and agree with the ',
    errorPolicyText: 'You must agree with the T&C and Privacy Policy',
    buttonTextAccept: 'Accept',
    nextPage: 'contact',
  },
  instructions: {
    title: 'How to Participate',
    steps: [
      {
        step: 'Step1: Try Out the Pulpy Experience.',
      },
      {
        step: 'Step2: Get your AI generated video with Shraddha Kapoor.',
      },
      {
        step: 'Step3: Share your creation on social media and tag #MMPulpyOrange for an incredible opportunity to meet Shraddha Kapoor!',
      },
    ],
  },
  contactPage: {
    deliveryTitle: 'Enter Contact Information',
    deliveryDescription: 'Please provide your phone number to verify your participation.',
    phoneSubtitle: '* Phone Number (Required)',
    phonePlaceholder: '(+91)',
    dobSubtitle: '* Date of Birth (Optional)',
    dobPlaceholder: 'DD/MM/YYYY',
    checkboxText: 'I certify that I am at least 18 years old and have read and agree with the Terms and Conditions and Privacy Policy',
    continueButton: 'Continue',
    backPage: 'main',
    nextPage: 'otp',
  },
  otpPage: {
    deliveryTitle: 'OTP Verification',
    deliveryDescription: 'Enter the 6-digit code we sent to',
    continueButton: 'Continue',
    backPage: 'contact',
    nextPage: 'form',
  },
  uploadPage: {
    title: 'Upload a Photo',
    description: 'Upload a photo to add yourself to your creation.',
    subTitle: 'Photo Guidelines For Best Results',
    guidelines: [
      {
        guideImage: guideImage,
        title: 'For better results:',
        text1: 'Clear, not busy background.',
        text2: 'Colored photo.',
        text3: 'In-focus picture.',
        text4: 'Only one person showing.',
        text5: 'Image from the waist up.',
      }],
    uploadButtonText: 'Upload picture',
    supportedFormats: 'Supported formats: JPG, PNG, HEIC',
    iconClose: {src: iconClose, alt: 'close'},
    deliveryTitle: 'Choose Video Delivery',
    deliveryDescription: 'To safeguard your video output and maintain access to it, please share your WhatsApp number or email address for secure delivery and backup.',
    contactTitle: 'Enter WhatsApp Number or Email Address',
    contactPlaceholder: 'Phone',
    finalizeButtonText: 'Generate video',
    checkboxText: 'I consent to the use of my image and likeness to Coca-Cola India Pvt.Ltd. for the purpose of creating my AI video, and in any promotional material, advertising material or otherwise on any media for an unlimited period for the purpose of promoting this contest, without remuneration.',
    backPage: 'contact',
    nextPage: 'processing',
  },
  processingPage: {
    titleProcessing: 'Your pulpy adventure with Shraddha is...',
    title: 'Processing Video...',
    description: 'Share your creation and tag us on social media using #minutemaid for an incredible opportunity to meet Shraddha Kapoor!',
    estimatedTime: 2,
    contactInfo: '',
  },
  sharePage: {
    title: 'Video ready to share',
    subTitle: 'Your video has been sent to:',
    description: 'Share your creation and tag us on social media using #minutemaid for an incredible opportunity to meet Shraddha Kapoor!',
    shareButton: 'Share',
    backPage: 'flavorChoice',
    contactInfo: '',
    downloadButtonDesktop: {src: downloadButtonDesktop, alt: 'download'},
    downloadButtonMobile: {src: downloadButtonMobile, alt: 'download '},
  },
  videoPlayer: {
    playButton: {src: playButton, alt: 'play'},
    pauseButton: {src: pauseButton, alt: 'pause'},
  },
  privacyModalText: {
    title: 'Privacy Policy',
    text:
      {
        elements: [
          {
            type: 'p',
            content: 'Last Revised:  Oct 10, 2023',
            class: 'date',
          },
          {
            type: 'p',
            content: 'Coca-Cola India Private Limited ("CCIPL," "we," "us") indirect subsidiary of The Coca-Cola Company (“TCCC”) is committed to protecting the privacy of its visitors ("Visitors," "you") to our Internet world wide web site available at https://mmpoai.coke2home.com (the “Site”/ “App”) that links directly to this privacy policy about the protection of personal information online. We aim for the Site to be a safe and enjoyable environment for you.',
          },
          {
            type: 'p',
            content: 'This privacy policy (the "Policy") primarily describes:',
          },
          {
            type: 'ul', content: [
              'the information that we collect through the Site, which is hosted and operated from India;',
              'the use and role of cookies and similar technologies on our website;',
              'the security measures we have in place to protect the information we gather.',
            ],
          },
          {
            type: 'p',
            content: 'This Policy does not govern our collection of information through any web site, or by any other means, other than through the Site, or the collection of information by any parent, subsidiaries, sponsors or affiliated companies ("Affiliated Entities").',
          },
          {
            type: 'p',
            content: 'For the purposes of this Policy, Process/processing shall mean a wholly or partly automated operation or set of operations performed on digital personal data, and includes operations such as collection, recording, organization, structuring, storage, adaptation, retrieval, use, alignment or combination, indexing, sharing, disclosure by transmission, dissemination or otherwise making available, restriction, erasure or destruction.',
          },
          {
            type: 'p',
            content: 'We encourage you to read this Policy before using our Site. By using the Site, you agree to the terms and conditions of this Policy. If you do not agree to the terms and conditions of this Policy, please do not use the Site.',
          },
          {
            type: 'h3',
            content: '1. Information CCIPL Gathers Through the Site.',
            class: 'bold',
          },
          {
            type: 'p',
            content: 'CCIPL gathers two basic types of information through the Site: "Personal Information," information from which an individual can be identified, and "Aggregate Information," from which an individual cannot be identified.',
          },
          {
            type: 'p',
            content: 'a. Aggregate Information. When you visit and interact with the Site, CCIPL and third parties with whom CCIPL has contracted to provide services to CCIPL, may collect anonymous information from three sources: server log files, cookies, and "pixel tags".',
          },
          {
            type: 'p',
            content: `i. Server Log Files. Your Internet Protocol (IP) address is an identifying number that is automatically assigned to your computer by your Internet Service Provider (ISP). This number is identified and logged automatically in our server log files whenever you visit the Site, along with the time(s) of your visit(s) and the page(s) that you visited. We use the IP addresses of all Visitors to calculate Site usage levels, to help diagnose problems with the Site's servers, and to administer the Site. We may also use IP addresses to communicate or to block access by Visitors who fail to comply with our Terms of Service. Collecting IP addresses is standard practice on the Internet and is carried out automatically by many web sites.`,
          },
          {
            type: 'p',
            content: `ii. Cookies. Cookies are data that a web server transfers to an individual's computer for record keeping purposes. Cookies are an industry standard used by most web sites and help facilitate users' ongoing access to and use of a particular web site. Cookies do not cause damage to your computer systems or files, and only the web site that transferred a particular cookie to you can read, modify or delete such cookie. If you do not want information collected through the use of cookies, there are simple procedures in most browsers that allow you to delete existing cookies, to automatically decline cookies, or to be given the choice of declining or accepting the transfer of particular cookies to your computer. You should note, however, that declining cookies may make it difficult or impossible for you to use portions of the Site.`,
          },
          {
            type: 'p',
            content: `iii. Pixel Tags. The Site may use so-called "pixel tags," "web beacons," "clear GIFs" or similar means (collectively, "Pixel Tags") to compile aggregate statistics about Site usage and response rates. Pixel Tags allow us to count users who have visited certain pages of the Site, to deliver branded services, and to help determine the effectiveness of promotional or advertising campaigns. When used in HTML-formatted email messages, Pixel Tags can tell the sender whether and when the email has been opened.`,
          },
          {
            type: 'p',
            content: 'b. Personal Information. You are not ordinarily required to register or provide Personal Information in order to access our Site, though certain functionalities (such as a membership program, through which purchasers of products may receive discounts on online purchases) may require registration. We collect Personal Information from you only when you voluntarily provide it to us - for example, in contacting us through the Site, answering surveys and polls, entering contests, and similar promotions, signing up for email updates and announcements concerning our products and special promotions, and purchasing CCIPL merchandise. Personal Information may include your contact information (such as your name, postal address, mobile/telephone numbers and/or email address ), as well as your payment information, such as credit card number and expiration date ("Payment Information"), as and when required, which we may collect if you wish to purchase any product or service made available through the Site ("Transaction").',
          },
          {
            type: 'h3',
            content: '2. Use and Disclosure of Information CCIPL Gathers Through the Site.',
            class: 'bold',
          },
          {
            type: 'p',
            content: 'a. Personal Information. We may use your Personal Information in any of the following ways.',
          },
          {
            type: 'p',
            content: 'i. Communications. We encourage Visitors to contact us with questions and comments. Personal Information may be used in order to respond to your questions and comments.',
          },
          {
            type: 'p',
            content: 'ii. Business Purposes. We may also use your Personal Information for internal business purposes, such as analyzing and managing our businesses. The Personal Information you provide through the Site may be combined with other information you provide to CCIPL (via online or offline means), with demographic information and other information that is publicly available, or with other information about you that CCIPL may otherwise obtain online or offline in a legally compliant manner.',
          },
          {
            type: 'p',
            content: 'iii. Transactions. When you take part in a Transaction through the Site such as purchasing CCIPL merchandise, we may collect Payment Information from you related to such Transaction as well as other Personal Information such as a shipping address so that we can send you the products you have purchased. We may use your Personal Information or Payment Information in order to complete the Transaction and, if applicable, to fulfill your purchase. We may also provide such Payment Information to third parties as necessary to complete your Transaction (for example, to process your credit card).',
          },
          {
            type: 'p',
            content: `iv. Contests, competitions and other promotions. We may operate contests, competitions and similar promotions through the Site that may require online registration (which may include name, email, user ID and password). We typically ask you for certain Personal Information when you enter and participate in, if applicable, a contest, competition or similar promotion. We may share this Personal Information with third party sponsors of such contests, competitions and promotions (irrespective of whether it is hosted by us), or otherwise in accordance with the rules applicable to such contest, competition or promotion. You should carefully review the rules of each contest, competition and promotion in which you participate through the Site, as they may contain additional important information about CCIPL's or a sponsor's use of your Personal Information. To the extent that the terms and conditions of such rules concerning the treatment of your Personal Information conflict with this Policy, the terms and conditions of such rules shall control.`,
          },
          {
            type: 'p',
            content: 'v. Special Promotions and Product Information. In addition, CCIPL may use your Personal Information to provide you with updates and announcements concerning our products and special promotions via Email, SMS, WhatsApp and/or other communication mediums. You may opt-out of receiving these messages from us at any time (see "Contact Us" below).',
          },
          {
            type: 'p',
            content: 'vi. Critical Communications. From time to time, we may use your Personal Information to relay important information regarding the Site, your Transactions, or changes to our terms, conditions, and policies ("Critical Communications") to you. Because this information may be critical to your use of the Site, you may not opt-out of receiving these communications sent via emails or other media.',
          },
          {
            type: 'p',
            content: 'vii. Third Party Service Providers. We work with third parties who provide services, including web site hosting, credit card processing, data analysis, newsletter services, promotional activities, and other administrative services. We may share your Personal Information, including your Payment Information, with such third parties for the purpose of enabling such third parties to provide such services.',
          },
          {
            type: 'p',
            content: 'viii. Miscellaneous. Finally, we may disclose your Personal Information if we believe that we are required to do so: (i) by law; (ii) to comply with legal process or governmental requests; (iii) to enforce our Terms of Service; (iv) to protect our operations; (v) to protect the rights, privacy, safety or property of CCIPL, you or others; and (vi) to permit us to pursue available remedies or limit the damages that we may sustain.',
          },
          {
            type: 'p',
            content: 'b. Aggregate Information. We analyze Aggregate Information in order to enhance web site security, track the popularity of certain pages of the Site, the success of our email/other notifications, traffic levels on the Site and other usage data, all of which helps us to provide content tailored to your interests, improve the Site and related services and to otherwise enhance your experience on the Site. We may share Aggregate Information with Affiliated Entities and other third parties.',
          },
          {
            type: 'h3',
            content: '3. Your rights over the Personal Information collected by us. You have the following rights:',
            class: 'bold',
          },
          {
            type: 'p',
            content: 'a. to obtain a copy of your Personal Data together with information about how and on what basis that Personal Information is processed and the details of the Data processors with whom the Personal Information has been shared; ',
          },
          {
            type: 'p',
            content: 'b. to rectify inaccurate Personal Data (including the right to have incomplete Personal Data completed);',
          },
          {
            type: 'p',
            content: 'c. to request that we erase your Personal Data in limited circumstances.',
          },
          {
            type: 'p',
            content: 'd. to nominate another individual on your behalf to exercise these rights.',
          },
          {
            type: 'h3',
            content: '4. Other Important Notes Regarding Our Privacy Practices.',
            class: 'bold',
          },
          {
            type: 'p',
            content: `a. Special Note for Parents and Children. Our Site and the contents therein are intended for a general audience and not intended for utilization directly by children under 18. If you are a child under the age of 18 and desire to use this Site or purchase our applications or products or use our services, please request your parent to register with us and to complete the transaction. Any use of this Site or any of its features by a child under the age of 18 must be with parental supervision. We do not contact children under the age of 18 about special offers or for marketing purposes without a parent's permission. We do not knowingly collect Personal Information from any children under age of 18.`,
          },
          {
            type: 'p',
            content: 'b. Security. We have security measures and tools in place to help protect against the loss, misuse, and alteration of the information under our control. Any time you provide us with your credit card number, that credit card number is transmitted to us by your browser in an encrypted format using industry-standard, SSL (secure socket layer) encryption. No method of transmitting or storing data is completely secure. As a result, although we strive to protect your Personal Information, we cannot guarantee the security of any information you transmit to us through or in connection with the Site. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), you must immediately notify us of the problem by contacting us in accordance with "Contact Us," below (note that physical mail notification will delay the time it takes for us to respond to the problem).',
          },
          {
            type: 'p',
            content: `c.	Other Third-Party Sites. The Site may contain links to third party web sites. These linked sites (including the web sites of Affiliated Entities) are not under CCIPL's control, and we are not responsible for the privacy practices or the contents of any such linked site, or any link contained in any linked site. We provide such links only as a convenience, and the inclusion of a link on the Site does not imply endorsement of the linked site by CCIPL. If you provide Payment Information or other Personal Information through any such third-party web site, your transaction will occur on such third party's web site (not the Site) and the Personal Information you provide will be collected by, and controlled by the privacy policy of, that third party. It is important that you familiarize yourself with the privacy policies and practices of any such third parties.`,
          },
          {
            type: 'p',
            content: `d.	Retention of Personal Data. We will only retain your Personal Information for as long as it is necessary to satisfy the purpose for which it was provided by you or collected by us (for example, for the time necessary for us to answer queries or resolve problems). When the Personal Information that we collect is no longer required in this way, we destroy or delete it in a secure manner. In certain cases, we may have legal or regulatory obligations that require us to retain specific records for a set period of time.`,
          },
          {
            type: 'p',
            content: `e. Assignment. We reserve the right to transfer any and all information that we collect from users of the Site to a third party in the event of any merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of CCIPL's assets or stock (including without limitation in connection with any bankruptcy or similar proceedings).`,
          },
          {
            type: 'p',
            content: `f. International transfers of your Personal Information. The global nature of our business means that your Personal Information may be disclosed within the TCCC Group located in jurisdictions permitted by the laws of India. We also use some third-party suppliers to help us provide business services. These third parties may have access to or host your Personal Information but will always do so under our instructions and subject to a contractual relationship. When these third parties are located in territories which may not offer an equivalent level of protection to privacy as that applicable within India, we will take reasonable steps to verify that your Personal Information receives a reasonable level of protection.`,
          },
          {
            type: 'p',
            content: `g. Processing/process. a wholly or partly automated operation or set of operations performed on digital personal data, and includes operations such as collection, recording, organisation, structuring, storage, adaptation, retrieval, use, alignment or combination, indexing, sharing, disclosure by transmission, dissemination or otherwise making available, restriction, erasure or destruction.`,
          },
          {
            type: 'p',
            content: `h. Revocation of consent. You may revoke your consent to use/process your Personal Information by writing to us on the below mentioned Email address and we shall comply with it as promptly as possible. However, we may retain certain information as mentioned in Section 3(d).`,
          },
          {
            type: 'h3',
            content: '5. Updating Your Information and Contacting Us.',
            class: 'bold',
          },
          {
            type: 'p',
            content: 'CCIPL has procedures in place to keep your Personal Information accurate, complete and up to date for the purposes for which it is collected and used. You may review the information we have collected and where appropriate you may request that it be corrected. We also provide you with several options for reviewing, correcting, updating or otherwise modifying information you have previously provided:',
          },
          {
            type: 'ol', content: [
              'You may email us at: gocci@coca-cola.com',
              'You may call us at: 1800-208-2653',
            ],
          },
          {
            type: 'p',
            content: 'Please clearly indicate the information that you wish to review or have changed. We will endeavor to comply with your request as soon as reasonably possible.',
          },
          {
            type: 'p',
            content: 'Note that despite any removal of or change to Personal Information requested there may also be residual information that will remain within our databases and other records, which may not be removed or changed.',
          },
          {type: 'h3', content: '6. Choice', class: 'bold'},
          {
            type: 'p',
            content: 'From time to time, we may ask you to indicate whether you are interested in receiving emails and other information from CCIPL. If you elect to receive these communications, we will occasionally send you emails or other information that match your requests and offer you promotions and coupons.',
          },
          {
            type: 'p',
            content: 'If at any time you wish to stop receiving these communications from us, please just let us know by emailing or calling us using the contact information listed above in Contacting Us. Please indicate the type of communications that you wish to stop receiving from CCIPL.',
          },
          {
            type: 'p',
            content: `In addition, we do not disclose your Personal Information to third parties, including CCIPL's subsidiaries, for the third-party's direct marketing purposes if we have received and processed a request from you that your Personal Information not be shared with third parties for that purpose. If you would like to submit such a request, please just let us know by emailing or calling us using the contact information listed above in Contacting Us. Please indicate that you request that CCIPL not disclose your Personal Information to CCIPL's subsidiaries and/or other third parties for direct marketing.`,
          },
          {type: 'h3', content: '7. Changes to This Policy.', class: 'bold'},
          {
            type: 'p',
            content: 'We reserve the right to change this Policy, and any of our policies or procedures concerning the treatment of information collected through the Site, without prior notice. You can determine when this Policy was last revised by referring to the "Last Updated" legend at the top of this page. Any changes to our Policy will become effective upon posting of the revised Policy on the Internet, accessible through the Site. Use of the Site following such changes constitutes your acceptance of the revised Policy then in effect. We encourage you to bookmark this page and to periodically review it to ensure familiarity with the most current version of our Policy.',
          },
          {
            type: 'p',
            content: `This Policy represents the sole, authorized statement of CCIPL's practices with respect to the collection of Personal Information (defined below) through the Site and CCIPL's use of such information. Any summaries of this Policy generated by third party software or otherwise (for example, in connection with the Platform for Privacy Preferences or "P3P") shall have no legal effect, do not bind CCIPL and/or The Coca-Cola Company, shall not be relied upon in substitute for this Policy, and neither supersede nor modify this Policy.`,
          },
          {type: 'h3', content: '8. Consent Amendments', class: 'bold'},
          {
            type: 'p',
            content: 'By using this Site, you consent to the terms of this Policy and to our use and management of Personal Information for the purposes and in the manner herein provided. Should this Privacy Policy change, we intend to take every reasonable step to ensure that these changes are brought to your attention by posting all changes prominently on our website for a reasonable period of time.',
          },
          {
            type: 'p',
            content: 'Your visit and any dispute over privacy are subject to this Policy. The said Policy shall be governed by and construed in accordance with the laws of the Republic of India. Further, it is irrevocably and unconditionally agreed that the courts of Gurugram, India shall have exclusive jurisdiction to entertain any proceedings in relation to any disputes arising out of the same.',
          },
          {
            type: 'p',
            content: 'This Policy © 2023 The Coca-Cola India Private Limited. All rights reserved.',
          },
        ],
      },
  },
  termsModalText: {
    title: 'Terms and Conditions',
    text: {
      elements: [
        {
          type: 'p',
          content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        },
        {
          type: 'p',
          content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        },
        {
          type: 'p',
          content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        },
      ],
    },
  },
  footer: {
    title: '© 2023 The Coca-Cola Company. All rights reserved Trademark owner: The Coca-Cola Company',
  },
  formPage: {
    title: 'What’s Your Name?',
    subtitle: 'How Do You Pulp It?',

  },
  verbPage: {
    subtitle: 'How Do You Pulp It?',
    buttonReg: 'Regenerate',
    buttonNext: 'Unleash your pulpy face',
  },
  questionPage: {},
  imageCaptureHud: {
    arrowBtn: {src: arrowBtn, alt: 'next'},
  },
  imageCapturePage: {
    faceOverlay: {
      subTitle: 'Keep your face centered on your screen',
      square: {src: square, alt: 'square'},
    },
  },
  instructionButton: {
    title: 'How Do You Pulp It?'
  },
  imageConfirmPage: {},
  videoProcessingPage: {},
  videoResultPage: {},
}
