export const readFileData = async (callback: (reader: FileReader) => void): Promise<string | ArrayBuffer> => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', (event) => {
      if (!event.target?.result) {
        console.error('No file data!')
        reject()
        return
      }
      resolve(event.target.result)
    })
    callback(reader)
  })
}

export const imageFromDataUrl = (dataUrl: string): Promise<HTMLImageElement> => {
  const img = document.createElement('img') as unknown as HTMLImageElement
  img.src = dataUrl
  
  return new Promise((resolve) => {
    img.addEventListener('load', () => resolve(img))
  })
}