import { NormalizedLandmark } from "@mediapipe/tasks-vision"
import { Verb } from "./verbs"
import { segmentPeople } from "./mediaPipeUtils"
import { Box2, Vector2 } from "three"
import { imageFromDataUrl } from "./fileUtils"
import userCardBackground from "../../assets/userCard_background.png"
import userCardSplash from "../../assets/userCard_splash.png"
import userCardOrb from "../../assets/userCard_orb.png"

export const createUserCard = async (username: string, verb: Verb, userImage: HTMLImageElement, userLandmarks: NormalizedLandmark[]): Promise<HTMLCanvasElement> => {
  const canvas = document.createElement('canvas') as unknown as HTMLCanvasElement
  canvas.width = 1080
  canvas.height = 810
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No context found, can not draw image!')
  }

  await drawLayer(canvas, ctx, userCardBackground)
  await drawLayer(canvas, ctx, userCardSplash)
  await drawLayer(canvas, ctx, userCardOrb)
  drawText(canvas, ctx, username, verb)
  await drawFace(canvas, ctx, userImage, userLandmarks)

  return canvas
}

const drawLayer = async (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, data: string) => {
  const { width, height } = canvas
  const img = await imageFromDataUrl(data)

  ctx.drawImage(
    img,
    0, 0, img.width, img.height,
    0, 0, width, height
  )
}

const drawText = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, username: string, verb: Verb) => {
  let nameFontSize = 92
  ctx.textAlign = "left"
  ctx.textBaseline = "top"

  ctx.fillStyle = "#FFFFFF"
  ctx.font = `bold ${nameFontSize}px Moranga-Medium`
  let textMetrics = ctx.measureText(username)

  while (textMetrics.width > 576 && nameFontSize > 1) {
    nameFontSize--
    ctx.font = `bold ${nameFontSize}px Moranga-Medium`
    textMetrics = ctx.measureText(username)
  }

  ctx.fillText(username, 92, 92)

  ctx.fillStyle = "#000000"
  ctx.font = `${69}px Moranga-Medium`
  ctx.fillText(`${verb.form3} it!`, 92, 184)
}

const drawFace = async (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, userImage: HTMLImageElement, userLandmarks: NormalizedLandmark[]) => {
  const segmentCanvas = await segmentPeople(userImage)
  const segmentCtx = segmentCanvas.getContext('2d') as CanvasRenderingContext2D

  // Segment user
  segmentCtx.globalCompositeOperation = 'source-in'
  segmentCtx.drawImage(userImage, 0, 0)
  segmentCtx.globalCompositeOperation = 'destination-over'
  segmentCtx.fillStyle = "rgba(0, 0, 0, 0)"
  segmentCtx.fillRect(0, 0, segmentCanvas.width, segmentCanvas.height)

  // Find face bounds
  const faceBox = calculateFaceBox(userLandmarks)
  const {x, y, width, height} = getUserImageDrawDestination(segmentCanvas, faceBox, canvas)

  ctx.drawImage(
    segmentCanvas,
    0, 0, segmentCanvas.width, segmentCanvas.height,
    x,
    y,
    width,
    height
  )
}

const getUserImageDrawDestination = (
  srcCanvas: HTMLCanvasElement,
  faceBox: { bbox: Box2, size: Vector2 },
  dstCanvas: HTMLCanvasElement
): {x: number, y: number, width: number, height: number} => {
  const { width: srcWidth, height: srcHeight } = srcCanvas
  const { width: dstWidth, height: dstHeight } = dstCanvas
  const outputFaceMaxWidth = dstWidth / 2
  const outputFaceMaxHeight = dstHeight
  const maxFaceAspect = outputFaceMaxWidth / outputFaceMaxHeight
  const currentFaceAspect = (faceBox.size.width * srcWidth) / (faceBox.size.height * srcHeight)

  let outputFaceWidth, outputFaceHeight
  if (currentFaceAspect > maxFaceAspect) {
    outputFaceWidth = outputFaceMaxWidth
    outputFaceHeight = outputFaceMaxWidth / currentFaceAspect
  } else {
    outputFaceWidth = outputFaceMaxHeight * currentFaceAspect
    outputFaceHeight = outputFaceMaxHeight
  }
  const width = outputFaceWidth * (1 / faceBox.size.width)
  const height = outputFaceHeight * (1 / faceBox.size.height)
  const x = dstWidth - faceBox.bbox.max.x * width
  const y = dstHeight - faceBox.bbox.max.y * height

  return {
    x,
    y,
    width,
    height
  }
}

const calculateFaceBox = (landmarks: NormalizedLandmark[]): { bbox: Box2, size: Vector2 } => {
  const bbox = new Box2()
  const size = new Vector2()
  landmarks.forEach(landmark => bbox.expandByPoint(new Vector2(landmark.x, landmark.y)))
  bbox.getSize(size)

  // Expand the bounding box
  // At most, we want to expand the box by half it's width and height in each direction
  const expandX = size.x * 0.5
  const expandY = size.y * 0.5
  // We want to stop expanding in the x direction if x is less than 0 or greater than 1
  const minExpandX = Math.min(expandX, Math.max(Math.min(bbox.min.x, 1 - bbox.max.x), 0))
  // We want to stop expanding in the y direction if it's greater than 1
  const minExpandY = Math.min(expandY, Math.max(1 - bbox.max.y, 0))

  // left bound point
  bbox.expandByPoint(new Vector2(bbox.min.x - minExpandX, bbox.min.y))
  // right bound point
  bbox.expandByPoint(new Vector2(bbox.max.x + minExpandX, bbox.min.y))
  // bottom bound point
  bbox.expandByPoint(new Vector2(bbox.min.x, bbox.max.y + minExpandY))
  // top bound point - we don't use the min here as it might clip the user's forehead
  bbox.expandByPoint(new Vector2(bbox.min.x, bbox.min.y - expandY))

  bbox.getSize(size)

  return {
    bbox,
    size
  }
}

