export const swearsList = {
  list: [
    'aad',
    'aand',
    'bahenchod',
    'behenchod',
    'bhenchod',
    'bhenchodd',
    'b.c.',
    'bc',
    'bakchod',
    'bakchodd',
    'bakchodi',
    'bevda',
    'bewda',
    'bevdey',
    'bewday',
    'bevakoof',
    'bevkoof',
    'bevkuf',
    'bewakoof',
    'bewkoof',
    'bewkuf',
    'bhadua',
    'bhaduaa',
    'bhadva',
    'bhadvaa',
    'bhadwa',
    'bhadwaa',
    'bhosada',
    'bhosda',
    'bhosdaa',
    'bhosdike',
    'bhonsdike',
    'bhosdiki',
    'bhosdiwala',
    'bhosdiwale',
    'bhosadchodal',
    'bhosadchod',
    'bhosadchodal',
    'bhosadchod',
    'babbe',
    'babbey',
    'bube',
    'bubey',
    'bur',
    'burr',
    'buurr',
    'buur',
    'charsi',
    'chooche',
    'choochi',
    'chuchi',
    'chhod',
    'chod',
    'chodd',
    'chudne',
    'chudney',
    'chudwa',
    'chudwaa',
    'chudwane',
    'chudwaane',
    'chaat',
    'choot',
    'chut',
    'chute',
    'chutia',
    'chutiya',
    'chutiye',
    'dalaal',
    'dalal',
    'dalle',
    'dalley',
    'fattu',
    'gadha',
    'gadhe',
    'gadhalund',
    'gaand',
    'gand',
    'gandu',
    'gandfat',
    'gandfut',
    'gandiya',
    'gandiye',
    'goo',
    'gu',
    'gote',
    'gotey',
    'gotte',
    'hag',
    'haggu',
    'hagne',
    'hagney',
    'harami',
    'haramjada',
    'haraamjaada',
    'haramzyada',
    'haraamzyaada',
    'haraamjaade',
    'haraamzaade',
    'haraamkhor',
    'haramkhor',
    'jhat',
    'jhaat',
    'jhaatu',
    'jhatu',
    'kutta',
    'kutte',
    'kuttey',
    'kutia',
    'kutiya',
    'kuttiya',
    'kutti',
    'landi',
    'landy',
    'laude',
    'laudey',
    'laura',
    'lora',
    'lauda',
    'ling',
    'loda',
    'lode',
    'lund',
    'launda',
    'lounde',
    'laundey',
    'laundi',
    'loundi',
    'laundiya',
    'loundiya',
    'lulli',
    'maar',
    'maro',
    'marunga',
    'madarchod',
    'madarchodd',
    'madarchood',
    'madarchoot',
    'madarchut',
    'm.c.',
    'mc',
    'mamme',
    'mammey',
    'moot',
    'mut',
    'mootne',
    'mutne',
    'mooth',
    'muth',
    'nunni',
    'nunnu',
    'paaji',
    'paji',
    'pesaab',
    'pesab',
    'peshaab',
    'peshab',
    'pilla',
    'pillay',
    'pille',
    'pilley',
    'pisaab',
    'pisab',
    'pkmkb',
    'porkistan',
    'raand',
    'rand',
    'randi',
    'randy',
    'suar',
    'tatte',
    'tatti',
    'tatty',
    'ullu'
  ]
}
