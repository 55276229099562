import React, { useContext, useEffect } from 'react'
import './Modal.scss'
import AppContext from '../../AppContext'
import { useIsMobile } from '../../utils/viewport'
import classNames from 'classnames'

export interface ModalProps {
  children: React.ReactNode
  className?: string
}

export const Modal = ({children}: ModalProps) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('Modal must be used within an AppProvider')
  }

  const {isModalOpen, activeModalClass} = context
  const isMobile = useIsMobile()

  const modalViewPortStyle = classNames('modal__content', activeModalClass, {
    'modal__content--desktop': !isMobile,
    'slide-up-anim': activeModalClass !== 'instructions-modal',
    'slide-down-anim': activeModalClass === 'instructions-modal',
  })

  const modalClass = classNames({
    'modal--open': isModalOpen,
    'modal--hidden': !isModalOpen,
  })

  useEffect(() => {
    const modalContent = document.querySelector('.modal__content') as HTMLElement
    if (modalContent) {
      modalContent.classList.remove('slide-up-anim')
      modalContent.classList.remove('slide-down-anim')
      void modalContent.offsetWidth
      if (activeModalClass === 'instructions-modal') {
        modalContent.classList.add('slide-down-anim')
      } else {
        modalContent.classList.add('slide-up-anim')
      }
    }

    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [children, activeModalClass, isModalOpen])

  return (
    <div className={modalClass}>
      <div className={modalViewPortStyle}>
        {children}
      </div>
    </div>
  )
}


