import React, { useState, useRef } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import classNames from 'classnames'
import './DateInput.scss'

interface DateInputProps {
  label: string
  name: string
  className?: string
  isLoggedIn: boolean
}

export const DateInput = (props: DateInputProps) => {
  const { label, name, className, isLoggedIn } = props
  const { setFieldValue, values } = useFormikContext<any>()
  const [dateValue, setDateValue] = useState<string | null>(values[name])
  const dateInputRef = useRef<HTMLInputElement | null>(null)

  return (
    <div className={classNames('date-input-wrapper', className, { visibilityHidden: isLoggedIn })}>
      <Field
        id='dobField'
        className='dob-page__dobField date'
        type='date'
        name={name}
        innerRef={dateInputRef}
        value={dateValue || ''}
        onInput={(e: any) => {
          const value = e.target.value
          setDateValue(value)
          setFieldValue(name, value)
        }}
        onBlur={() => {
          if (!values[name] && dateInputRef.current) {
            dateInputRef.current.value = ''
            setDateValue(null)
          }
        }}
      />
      <label htmlFor='dobField' className='dob-page__dobLabel'>{label}</label>
      <ErrorMessage name={name} component='div' className='error' />
    </div>
  )
}
