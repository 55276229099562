import React from 'react'
import './LoadingPage.scss'
import loading from '../../assets/loading.gif'

export const LoadingPage = () => {
  return (
    <div className='loading-page'>
      <img src={loading} alt='Loading...'/>
    </div>
  )
}
