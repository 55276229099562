import React, { useContext } from 'react'
import { AppContext, DataComponents } from '../../AppContext'
import './ContactPage.scss'
import InstructionsButton from '../InstructionButton/InstructionButton'
import { PhoneNumberForm } from './PhoneNumberForm/PhoneNumberForm'
import { OtpForm } from './OtpForm/OtpForm'

interface ContactPageProps {
  phoneNumberData: DataComponents['contactPage']
  otpData: DataComponents['otpPage']
}

export const ContactPage = ({ phoneNumberData, otpData }: ContactPageProps) => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  return (
    <div className="contact-page page">
      <InstructionsButton showTitle={true} />
      <div className="contact-page__container">
        <PhoneNumberForm data={phoneNumberData} />
        <div className="separationLine" />
        <OtpForm data={otpData} />
      </div>
    </div>
  )
}
