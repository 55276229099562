import { Component } from "aframe"
import { Texture, MeshBasicMaterial, DoubleSide, BufferAttribute, Mesh } from "three"
import { FaceDetails } from "../XRTypes"

export interface AnimateFaceComponent extends Component {
  once: boolean
}

// @ts-ignore
export const animateFace: AnimateFaceComponent = {
  init() {
    this.once = true
    const faceTextureGltf_ = new Texture()
    const materialGltf = new MeshBasicMaterial({map: faceTextureGltf_, side: DoubleSide})
    let faceGltf: Mesh

    this.el.addEventListener('model-loaded', () => {
      faceGltf = this.el.getObject3D('mesh') as Mesh
      let materialReplaced = false

      faceGltf.traverse((child) => {
        const node = child as Mesh
        if (!materialReplaced || node.material) {
          node.material = materialGltf
          materialReplaced = true
        }
      })
    })

    const onxrloaded = () => {
      window.XR8.addCameraPipelineModule({
        name: 'cameraFeedPipeline',
        onUpdate: (processCpuResult) => {
          if (!processCpuResult) {
            console.log('no processCpuResult')
            return
          }

          const result = processCpuResult.processCpuResult

          if (result.facecontroller && result.facecontroller.cameraFeedTexture && this.el.sceneEl) {
            const {cameraFeedTexture} = processCpuResult.processCpuResult.facecontroller
            const texPropsGltf = this.el.sceneEl.renderer.properties.get(faceTextureGltf_)
            texPropsGltf.__webglTexture = cameraFeedTexture
          }
        }
      })
    }

    window.XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded)

    const show = (e: any) => {
      const event = e as CustomEvent<FaceDetails>
      const {uvsInCameraFrame} = event.detail

      const vertices = new Float32Array(event.detail.vertices.length * 3)
      // Update vertex positions.
      for (let i = 0; i < event.detail.vertices.length; ++i) {
        vertices[i * 3] = event.detail.vertices[i].x
        vertices[i * 3 + 1] = event.detail.vertices[i].y
        vertices[i * 3 + 2] = event.detail.vertices[i].z
      }

      const face = faceGltf.children[0] as Mesh

      face.geometry.setAttribute('position', new BufferAttribute(vertices, 3))
      face.geometry.attributes.position.needsUpdate = true

      const uvs = new Float32Array(uvsInCameraFrame.length * 2)
      for (let i = 0; i < uvsInCameraFrame.length; ++i) {
        uvs[i * 2] = uvsInCameraFrame[i].u
        uvs[i * 2 + 1] = uvsInCameraFrame[i].v
      }

      face.geometry.setAttribute('uv', new BufferAttribute(uvs, 2))

      // Update vertex normals.
      const normals = face.geometry.attributes.normal
      for (let i = 0; i < event.detail.normals.length; ++i) {
        normals.setXYZ(
          i * 3,
          event.detail.normals[i].x,
          event.detail.normals[i].y,
          event.detail.normals[i].z
        )
      }

      face.geometry.attributes.normal.needsUpdate = true
    }

    this.el.sceneEl?.addEventListener('xrfacefound', show)
    this.el.sceneEl?.addEventListener('xrfaceupdated', show)
  },
  remove() {
    window.XR8.removeCameraPipelineModule('cameraFeedPipeline');
  },
}
