import React from 'react'
import './VideoProcessingPage.scss'
import { DataComponents } from '../../AppContext'
import bottle from '../../assets/PuplyBottle.png'
import classNames from 'classnames'
import InstructionsButton from '../InstructionButton/InstructionButton'

export const VideoProcessingPage = ({
  data
}: {
  data: DataComponents['videoProcessingPage']
}) => {
  return (
    <div className="VideoProcessingPage--container">
      <div className={classNames('waves-container', 'animate-waves')}>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255, 121, 0,0.7)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255, 121, 0,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255, 121, 0,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#FF7900" />
          </g>
        </svg>
        <div className="filler"></div>
      </div>
      <InstructionsButton />
      <div className="VideoProcessingPage--splash1"></div>
      <div className="VideoProcessingPage--splash2"></div>
      <div className="VideoProcessingPage">
        <img
          src={bottle}
          alt="Pulpy Bottle"
          className="VideoProcessingPage--bottle"
        />
        <h1 className="VideoProcessingPage--loading-title">Your Pulpy Experience is loading</h1>
        <h3 className="VideoProcessingPage--loading-subtitle">this may take one - two minutes to load</h3>
        <p className="VideoProcessingPage--description">
          Share your creation and tag us on social media using #MMPulpyOrange for
          an incredible opportunity* to meet Shraddha Kapoor!
        </p>
      </div>
    </div>
  )
}
