import React, { useContext, useCallback } from 'react'
import './OtpForm.scss'
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { AppContext, DataComponents } from '../../../AppContext'
import { setStoredOtp } from '../../../utils/storageUtils'
import VerificationCodeInput from 'react-verification-input'
import { LoadingIcon } from '../../LoadingIcon/LoadingIcon'
import classNames from 'classnames'

interface FormValues {
  otp: string
}

const validationSchema = Yup.object({
  otp: Yup.string().required('Please enter OTP'),
})

export const OtpForm = ({ data }: { data: DataComponents['otpPage'] }) => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const {
    setNextPage,
    validateOtp,
    validateOtpRequest,
    contactInfo,
    otpSent,
    login,
  } = context

  const handleSubmit = useCallback(
    (values: FormValues, { setSubmitting, setFieldError }: FormikHelpers<FormValues>) => {
      const otp = values.otp
      if (otp && validateOtp && contactInfo && login && setNextPage) {
        validateOtp(otp, contactInfo)
          .then((response) => {
            if (response.status !== 'success') {
              setFieldError('otp', 'Incorrect code. Please try again.')
            } else {
              setStoredOtp(otp)
              login({
                name: response.data.name,
                dob: response.data.dob,
                consent: response.data.consent,
              })
              setNextPage(data.nextPage)
            }
          })
          .catch(() => {
            setFieldError('otp', 'An error occurred. Please try again.')
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    },
    [validateOtp, contactInfo, login, setNextPage, data.nextPage],
  )

  return (
    <Formik
      initialValues={{ otp: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => (
        <div className={classNames('otp-form', { 'visibilityHidden': !otpSent })}>
          <h3 className='otp-form__title'>{data.deliveryTitle}</h3>
          <p className='otp-form__subTitle'>
            {data.deliveryDescription} {contactInfo}
          </p>
          <Form>
            <VerificationCodeInput
              length={6}
              validChars='0-9'
              placeholder='.'
              onChange={(value) => setFieldValue('otp', value)}
            />
            {touched.otp && errors.otp && (
              <div className='error-wrapper'>
                <ErrorMessage name='otp' component='div' className='error' />
              </div>
            )}
            <button
              className='blueButton otp-form__submitBtn'
              type='submit'
              disabled={isSubmitting || validateOtpRequest?.loading}
            >
              {validateOtpRequest?.loading ? <LoadingIcon /> : 'Continue'}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  )
}
