import React, { useContext, useRef, useState } from 'react'
import './VideoResultPage.scss'
import AppContext, { DataComponents } from '../../AppContext'
import { PlayButton } from './PlayButton/PlayButton'
import { ShareButton } from './ShareButton/ShareButton'
import { useIsMobile } from '../../utils/viewport'
import { DownloadButton } from './DownloadButton/DownloadButton'
import InstructionsButton from '../InstructionButton/InstructionButton'
import { SocialLinks } from './SocialLinks/SocialLinks'
import videoThumbnail from '../../assets/videoThumbnail.png'
import classNames from 'classnames'

export const VideoResultPage = ({ data }: { data: DataComponents['videoResultPage'] }) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('VideoResultPage must be used within an AppProvider')
  }

  const [videoPlaying, setVideoPlaying] = useState(false)
  const [playButtonVisible, setPlayButtonVisible] = useState(true)
  const { outputVideo, goToVerbPage } = context
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMobile = useIsMobile()

  const _setVideoPlaying = (play: boolean) => {
    setVideoPlaying(play)
  }

  const handleReplay = () => {
    if (!videoRef.current) return

    videoRef.current.play()
    setPlayButtonVisible(true)
    _setVideoPlaying(true)
  }

  const handleVideoClick = () => {
    if (isMobile && videoRef.current && videoPlaying) {
      videoRef.current.pause()
      setVideoPlaying(false)
    }
  }



  return (
    <>
      {!isMobile && (
        <div className="HeroWrapper">
          <InstructionsButton />
          <h1>How do you pulp it?</h1>
        </div>
      )}

      <div className="VideoResultPage">
        <div className="VideoWrapper">
          <video
            src={outputVideo}
            ref={videoRef}
            poster={videoThumbnail}
            playsInline={true}
            onClick={handleVideoClick}
            onEnded={() => {
              setPlayButtonVisible(false)
              _setVideoPlaying(false)
            }}
          />
          {(!isMobile || playButtonVisible) && (
            <PlayButton
              videoRef={videoRef}
              videoPlaying={videoPlaying}
              setVideoPlaying={_setVideoPlaying}
            />
          )}
        </div>
        <div className="separationLine"></div>
        {(!playButtonVisible || !isMobile) && (
          <div className="buttonsWrapper">
            {outputVideo && <DownloadButton />}
            {!isMobile && <SocialLinks />}
            {outputVideo && <ShareButton />}
            <button
              className={classNames({ 'tryAgain': !isMobile, 'tryAgain--mobile': isMobile })}
              onClick={goToVerbPage}
            >
              Try again
            </button>
            {isMobile && (
              <button className="replayVideo" onClick={handleReplay}>
                Replay Video
              </button>
            )}
          </div>
        )}
      </div>
    </>
  )
}
