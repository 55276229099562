import AppContext from '../../AppContext'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import './InstructionsModal.scss'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

export const InstructionsModal = ({className = ''}) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const {data, closeModal, isModalOpen, isLoggedIn, logout} = context
  let modalData = data.instructions
  const modalContainerRef = useRef<HTMLDivElement>(null)

  modalData.steps = modalData.steps.map((step, index) => ({
    ...step,
    step: step.step
      .replace('#MMPulpyOrange', `<span class='highlight'>#MMPulpyOrange</span>`)
      .replace(`Step${index + 1}:`, `<span class='highlight'>Step${index + 1}:</span>`)
  }))

  useEffect(() => {
    if (isModalOpen && modalContainerRef.current) {
      modalContainerRef.current.scrollTop = 0
    }
  }, [isModalOpen])

  const handleClose = useCallback((event: any) => {
    event.stopPropagation()
    event.preventDefault()
    closeModal()
  }, [closeModal])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }

    if (isModalOpen) {
      document.addEventListener('keydown', handleEscape)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [closeModal, isModalOpen])

  return (
    <div className='instructions' ref={modalContainerRef}>
      <div className='instructions__container'>
        <h2 className='instructions__title'>{modalData.title}</h2>
        <ul>
          {modalData.steps.map((step, index) => (
            <li key={index}>
              <span dangerouslySetInnerHTML={{__html: step.step}}/>
            </li>
          ))}
        </ul>
        {createPortal(
          <button
            className={classNames('instructions__close', { 'visible': isModalOpen })}
            type='button'
            onClick={handleClose}
          />,
          document.body
        )}
        {isLoggedIn && <button className='strokeButtonOrange' onClick={logout}>Log out</button>}
      </div>
    </div>
  )
}
