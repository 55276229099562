import React, { useCallback } from 'react'
import whatsappLogo from '../../../assets/logo_whatsapp.svg'
import instagramLogo from '../../../assets/logo_instagram.svg'
import twitterLogo from '../../../assets/logo_twitter.svg'
import messangerLogo from '../../../assets/logo_messanger.svg'
import facebookLogo from '../../../assets/logo_facebook.svg'
import './SocialLinks.scss'
import { trackVideoShare } from '../../../api/cdsTracking'

export const SocialLinks = () => {
  const handleClick = useCallback(() => {
    trackVideoShare()
  }, [])
  return (
    <div className="SocialLinks">
      <p className="shareDescription">
        Share your creation and tag us on social media using #MMPulpyOrange for
        an incredible opportunity to meet Shraddha Kapoor!
      </p>

      <div className="socialLogosWrapper">
        <a
          href={`https://api.whatsapp.com/send?text=${window.location.hostname}`}
          target="_blank"
          rel="noreferrer"
          className="whatsapp-logo"
          onClick={handleClick}
        >
          <img src={whatsappLogo} alt="Whatsapp icon" />
        </a>
        <a
          // It is not possible to share to instagram directly
          href={`https://www.instagram.com/`}
          target="_blank"
          rel="noreferrer"
          className="instagram-logo"
          onClick={(event) => {
            handleClick()
            event.preventDefault()
            window.navigator.clipboard
              .writeText(window.location.hostname)
              .then(() => window.open('https://www.instagram.com/', '_blank'))
          }}
        >
          <img src={instagramLogo} alt="Instagram icon" />
        </a>
        <a
          href={`https://www.twitter.com/share?url=${window.location.hostname}`}
          target="_blank"
          rel="noreferrer"
          className="twitter-logo"
          onClick={handleClick}
        >
          <img src={twitterLogo} alt="Twitter icon" />
        </a>
        <a
          // Redirect to new message on Messanger after copying to the clipboard the website urls
          href={`https://www.facebook.com/messages/new`}
          target="_blank"
          rel="noreferrer"
          className="messanger-logo"
          onClick={(event) => {
            handleClick()
            event.preventDefault()
            window.navigator.clipboard
              .writeText(window.location.hostname)
              .then(() =>
                window.open('https://www.facebook.com/messages', '_blank')
              )
          }}
        >
          <img src={messangerLogo} alt="Messanger icon" />
        </a>
        <a
          href={`https://www.facebook.com/share.php?u=${window.location.hostname}`}
          target="_blank"
          rel="noreferrer"
          className="facebook-logo"
          onClick={handleClick}
        >
          <img src={facebookLogo} alt="Facebook icon" />
        </a>
      </div>
    </div>
  )
}
