import platform from 'platform'

export function useIsBrowserSupported(): boolean {
  const userAgentData = navigator.userAgent
  const browserInfo = platform.parse(userAgentData)
  const isSupported =
    browserInfo.name?.includes('Chrome') ||
    browserInfo.name?.includes('Safari') ||
    browserInfo.name?.includes('Samsung')

  if (isSupported) {
    return true
  } else {
    return false
  }
}

export const isIos = (): boolean => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}