import { getStoredOtp, getStoredPhoneNumber } from "../utils/storageUtils"

export interface GenerateVideoRequestData {
  phoneNumber?: string
  pin?: number
  imageData: string
}

export interface GenerateVideoError {
  status: 'invalid request body' | 'imageData is required' | 'video processing error'
}

export const getGenerateVideoUrl = () => {
  return `${process.env.REACT_APP_VIDEO_API}`
}

export const generateVideo = (data: GenerateVideoRequestData): Promise<string> => {
  if (!data.phoneNumber) {
    data.phoneNumber = getStoredPhoneNumber()
  }

  if (!data.pin) {
    data.pin = parseInt(getStoredOtp())
  }

  return fetch(getGenerateVideoUrl(), {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      'Content-Type': "application/json",
    }),
    body: JSON.stringify(data)
  })
  .then((response) => response.blob())
  .then((videoBlob) => {
    const reader = new FileReader()
    const dataUrlPromise: Promise<string> = new Promise((resolve, reject) =>
      reader.addEventListener('loadend', () => resolve(reader.result as string))
    ).then((dataUrl) => (dataUrl as string).replace('application/octet-stream', 'video/mp4'))
    reader.readAsDataURL(videoBlob)

    return dataUrlPromise
  })
}