import { useEffect, useState } from 'react'

export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.outerWidth < 769
      const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
      const isIpad =
        navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2

      if (isMobile || isMobileDevice || isIpad) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}
