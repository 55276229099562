import AppContext from '../../AppContext'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import './PrivacyAndTermsModal.scss'
import closeIcon from "../../assets/closeButton.svg";
import logo from "../../assets/logo.png";
import { useIsMobile } from "../../utils/viewport";

export const PrivacyAndTermsModal = ({ type }: { type: 'PrivacyModal' | 'TermsModal' }) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const { closeModal, isModalOpen } = context
  const modalData = type === 'PrivacyModal' ? context.data.privacyModalText : context.data.termsModalText
  const modalContainerRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isModalOpen && modalContainerRef.current) {
      modalContainerRef.current.scrollTop = 0
    }
  }, [isModalOpen])

  const handleClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    <div className='privacy-terms' ref={modalContainerRef}>
      <div className='container'>
        <button className='privacy-terms__closeButton' type='button' onClick={handleClose}>
          <img src={closeIcon} alt="close modal"/>
        </button>
        {!isMobile && <img className='privacy-terms__logo' src={logo} alt='open modal'/>}
        <h2 className='privacy-terms__title'>{modalData.title}</h2>
        {modalData.text.elements.map((element, index) => {
          switch (element.type) {
            case 'p':
              return <p className={`privacy-terms__description justify ${element.class || ''}`} key={index}>
                {element.content}
              </p>
            case 'h3':
              return <h3 className={`privacy-terms__description justify ${element.class || ''}`} key={index}>
                {element.content}
              </h3>
            case 'ul':
              return (
                <ul key={index}>
                  {Array.isArray(element.content) && element.content.map((item, index) =>
                    <li key={index}>
                      {item}
                    </li>
                  )}
                </ul>
              )
            case 'ol':
              return (
                <ol key={index}>
                  {Array.isArray(element.content) && element.content.map((item, index) =>
                    <li key={index}>
                      {item}
                    </li>
                  )}
                </ol>
              )
            default:
              return null
          }
        })}


        <button className='privacy-terms__button blackButton' type='button' onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  )
}
