import React, { useCallback, useContext } from 'react'
import './DownloadButton.scss'
import { useIsMobile } from '../../../utils/viewport'
import AppContext from '../../../AppContext'
import { trackVideoDownload } from '../../../api/cdsTracking'
import { saveAs } from 'file-saver'

export const DownloadButton = () => {
  const isMobile = useIsMobile()
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('VideoResultPage must be used within an AppProvider')
  }

  const { outputVideo } = context

  const handleClick = useCallback(() => {
    trackVideoDownload()
    fetch(outputVideo)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'MinuteMaid_Video.mp4')
      })
      .catch((e) => console.error('Error: ', e))
  }, [outputVideo])

  const buttonStyle = isMobile
    ? {
      anchorClass: '',
      buttonClass: 'DownloadButton--mobile',
      buttonText: 'Download',
    } : {
      anchorClass: 'DownloadButton--anchor',
      buttonClass: 'DownloadButton--desktop',
      buttonText: 'Download',
    }

  return (
    <div className={buttonStyle.anchorClass}>
      <button className={buttonStyle.buttonClass} onClick={handleClick}>
        {buttonStyle.buttonText}
      </button>
    </div>
  )
}
