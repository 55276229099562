import React, { useContext, useEffect, useRef, useState } from 'react'
import './FaceOverlay.scss'
import classNames from 'classnames'
import AppContext, { DataComponents } from '../../../AppContext'

export const FaceOverlay = ({data}: { data: DataComponents['imageCapturePage']['faceOverlay'] }) => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const {
    isCameraPermissionGranted,
    setIsOverlayVisible
  } = context

  const [overlayOpacity, setOverlayOpacity] = useState(0)
  const [squareOpacity, setSquareOpacity] = useState(0)
  const [animateSquare, setAnimateSquare] = useState(false)

  const overlayRef = useRef(null)

  useEffect(() => {
    if (!isCameraPermissionGranted) return

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setOverlayOpacity(1)
        setSquareOpacity(1)
        setAnimateSquare(true)

        setTimeout(() => {
          setOverlayOpacity(0)
          setSquareOpacity(0)
          setAnimateSquare(false)
          setIsOverlayVisible(false)
        }, 4000)
      }
    })

    if (overlayRef.current) {
      observer.observe(overlayRef.current)
    }

    return () => observer.disconnect()
  }, [isCameraPermissionGranted, setIsOverlayVisible])

  return (
    <div
      ref={overlayRef}
      className={classNames('FaceOverlay', {'non-interactive': overlayOpacity === 0})}
      style={{opacity: overlayOpacity}}
    >
      <img
        src={data.square.src}
        alt={data.square.alt}
        className={`OverlaySquare ${animateSquare ? 'animate' : ''}`}
        style={{opacity: squareOpacity}}
      />
      <p className='InstructionText'>{data.subTitle}</p>
    </div>
  )
}
