import { getCdsUserIdentifier } from "../utils/storageUtils"

export interface SendOtpRequestData {
  phoneNumber?: string
  cdsUserIdentifier?: string
  resend?: boolean
}

export interface SendOtpResponse {
  status: 'success'
}

export interface SendOtpError {
  status: 'invalid phoneNumber' | 'invalid email' | 'server error'
}

export const getSendOtpUrl = () => {
  return `${process.env.REACT_APP_API_BASE_URL}/send-otp`
}

export const sendOtp = (data: SendOtpRequestData, resend = false): Promise<SendOtpResponse> => {
  data.cdsUserIdentifier = getCdsUserIdentifier()
  data.resend = resend

  return fetch(getSendOtpUrl(), {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      'Content-Type': "application/json",
    }),
    body: JSON.stringify(data)
  }).then((response) => response.json())
}