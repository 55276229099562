import React, { useCallback, useContext } from 'react'
import AppContext, { DataComponents } from '../../AppContext'
import './Footer.scss'

export const Footer = ({data}: { data: DataComponents['footer'] }) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const {setModalContent, openModal} = context

  const handleModalOpen = useCallback(
    (type: 'PrivacyModal' | 'TermsModal', event: any) => {
      event.preventDefault()
      setModalContent(type)
      openModal('Footer')

    },
    [setModalContent, openModal])

  return (
    <div className='footer' id="footer">
      <h2 className='footer__title'>{data.title}</h2>
      <button type='button' className='buttonLink' onClick={(e) => handleModalOpen('TermsModal', e)}>
        <sup>*</sup>Terms and Conditions
      </button>
      <span> | </span>
      <button type='button' className='buttonLink' onClick={(e) => handleModalOpen('PrivacyModal', e)}>
        Privacy Policy
      </button>
    </div>
  )
}

