export const wrappingModulo = (index: number, total: number): number => {
  return ((index % total) + total) % total
}

export const transformRange = (value: number, fromRange: [number, number], toRange: [number, number], clampped?: boolean): number => {
  const [fromMin, fromMax] = fromRange
  const fromDiff = fromMax - fromMin
  const [toMin, toMax] = toRange
  const toDiff = toMax - toMin
  const scale = toDiff / fromDiff
  const transformedVal = (value - fromMin) * scale + toMin

  return clampped
    ? clamp(transformedVal, toRange)
    : transformedVal
}

const clamp = (value: number, range: [number, number]): number => {
  const [min, max] = range
  if (max < min) return clamp(value, [max, min])
  return Math.min(max, Math.max(min, value))
}