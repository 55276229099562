import React, { useContext, useEffect } from 'react'
import './ImageCaptureHud.scss'
import AppContext from '../../../AppContext'
import { expressions } from '../../../utils/video/expressions'
import { EditExpression } from '../EditExpression/EditExpression'
import InstructionsButton from '../../InstructionButton/InstructionButton'
import { wrappingModulo } from '../../../utils/mathUtils'
import { flipImageData } from '../../../utils/canvasUtils'


const editExpressions = false
export const ImageCaptureHud = ({contentOpacity}: { contentOpacity: number }) => {
  // const [submitting, setSubmitting] = useState(false)
  const context = useContext(AppContext)
  if (!context) throw new Error('ImageCaptureHud requires AppContext.')
  const data = context.data.imageCaptureHud
  const scene = document.querySelector('a-scene')

  const {
    username,
    verb,
    expressionIndex,
    setExpression,
    setExpressionIndex,
    setCurrentPage,
    setUserImage,
    setDisplayImageConfirmPage
  } = context


  const handleExpressionChange = (direction: number) => {
    const newIndex = wrappingModulo(expressionIndex + direction, expressions.length)
    setExpressionIndex(newIndex)
    setExpression(expressions[newIndex])
  }

  useEffect(() => {
    if (!scene) return

    const onScreenshotReady = (e: CustomEvent<string>) => {
      const imageData = 'data:image/jpeg;base64,' + e.detail
      flipImageData(imageData).then((flippedImageData) => {
        setUserImage(flippedImageData)
        setDisplayImageConfirmPage(true)
      })
    }

    const onScreenshotError = (event: Event) => {
      console.error('There was a problem taking a screenshot!', event)
      // setSubmitting(false)
    }

    scene.addEventListener('screenshotready', onScreenshotReady as EventListener)
    scene.addEventListener('screenshoterror', onScreenshotError)

    return () => {
      scene.removeEventListener('screenshotready', onScreenshotReady as EventListener)
      scene.removeEventListener('screenshoterror', onScreenshotError)
    }
  }, [scene, setCurrentPage, setUserImage, setDisplayImageConfirmPage])

  const handleDone = () => {
    if (!scene) return

    // setSubmitting(true)
    scene.emit('screenshotrequest')
  }


  const NavigationButtons = (
    <div className='NavigationButtons'>
      <button className='LeftArrow' onClick={() => handleExpressionChange(-1)}>
        <img src={data.arrowBtn.src} alt={data.arrowBtn.alt}/>
      </button>

      <button className='RightArrow' onClick={() => handleExpressionChange(1)}>
        <img src={data.arrowBtn.src} alt={data.arrowBtn.alt}/>
      </button>
    </div>
  )


  if (editExpressions) {
    return (
      <div className='ImageCaptureHud'>
        <EditExpression/>
      </div>
    )
  }

  return (
    <div className='ImageCaptureHud' style={{opacity: contentOpacity}}>
      <InstructionsButton/>

      <h1 className='Phrase'>{username}, how do you {verb.form1} it?</h1>

      <div className='buttons-wrapper'>
        {NavigationButtons}
        <div className='Done__wrapper'>
          <button className='DoneButton' onClick={handleDone}></button>
        </div>
      </div>
    </div>
  )
}

