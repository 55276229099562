import React, { useContext, useEffect } from 'react'
import './ImageCapturePage.scss'
import AppContext, { DataComponents } from '../../AppContext'
import { ImageCaptureHud } from './ImageCaptureHud/ImageCaptureHud'
import { FaceMorphScene } from '../FaceMorphScene/FaceMorphScene'
import { FaceOverlay } from './FaceOverlay/FaceOverlay'
import { useIsMobile } from '../../utils/viewport'
import { useIsLandscape } from '../../utils/orientation'
import { ImageConfirmPage } from './ImageConfirmPage/ImageConfirmPage'
import { CameraDeniedPage } from './CameraDeniedPage/CameraDeniedPage'
import classNames from 'classnames'

export const ImageCapturePage = ({
  data
}: {
  data: DataComponents['imageCapturePage']
}) => {
  const context = useContext(AppContext)
  const isMobile = useIsMobile()
  const isLandscape = useIsLandscape()

  if (!context) {
    throw new Error(
      'AppContext is undefined, ensure it is provided by a context provider'
    )
  }

  const {
    isOverlayVisible,
    displayImageConfirmPage,
    displayCameraDeniedPage,
    setDisplayCameraDeniedPage,
    closeModal
  } = context
  const hudOpacity = isOverlayVisible ? 0 : 1

  useEffect(() => {
    const permissionName = 'camera' as PermissionName

    navigator.mediaDevices.enumerateDevices().then((result) => {
      const hasCamera = result.some((device) => device.kind === 'videoinput')
      if (!hasCamera) {
        setDisplayCameraDeniedPage(true)
        return
      }
    })

    navigator.permissions.query({ name: permissionName }).then((result) => {
      if (result.state === 'denied') {
        setDisplayCameraDeniedPage(true)
      }

      result.onchange = () => {
        if (result.state === 'denied') {
          setDisplayCameraDeniedPage(true)
        } else if (result.state === 'granted') {
          setDisplayCameraDeniedPage(false)
          closeModal()
        }
      }
    })
  }, [setDisplayCameraDeniedPage, closeModal])

  useEffect(() => {
    const sceneElement = document.getElementById('aframeScene')

    if (displayImageConfirmPage) {
      sceneElement!.style.visibility = 'hidden'
    } else {
      sceneElement!.style.visibility = 'visible'
    }
  }, [displayImageConfirmPage])

  useEffect(() => {
    const loadingElement = document.getElementById('loadingContainer')
    const sceneElement = document.getElementById('aframeScene')
    if (displayCameraDeniedPage) {
      sceneElement!.style.visibility = 'hidden'
      loadingElement!.style.visibility = 'hidden'
    } else {
      sceneElement!.style.visibility = 'visible'
      loadingElement!.style.visibility = 'visible'
    }
  }, [displayCameraDeniedPage])

  return (
    <>
      <div
        className={classNames('ImageCapturePage', 'page', {
          visibilityHidden:
            displayImageConfirmPage ||
            (isMobile && isLandscape) ||
            displayCameraDeniedPage
        })}
      >
        <FaceOverlay data={data.faceOverlay} />
        <div className='container container-wrapper'>
          <ImageCaptureHud contentOpacity={hudOpacity} />
          <FaceMorphScene />
        </div>
      </div>

      {displayImageConfirmPage && (
        <div className='overlay-imageCapturePage'>
          <ImageConfirmPage data={context.data.imageConfirmPage} />
          {/* <UserCardTestPage /> */}
        </div>
      )}

      {displayCameraDeniedPage && (
        <div className='overlay-imageCapturePage'>
          <CameraDeniedPage />
        </div>
      )}
    </>
  )
}
