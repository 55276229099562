import { MouseEvent, createContext } from 'react'
import { Expression } from './utils/video/expressions'
import { Verb } from './utils/video/verbs'
import { SendOtpRequestInfo, ValidateOtpRequestInfo } from './AppProvider'
import { ValidateOtpResponse } from './api/validateOtp'


export interface DataComponents {
  mainPage: {
    title: string,
    subtitle: string,
    cardsMobile: { src: string, alt: string },
    cardsDesktop: { src: string, alt: string },
    paragraph?: string,
    buttonText: string,
  },
  cookiePolicy: {
    title: string,
    message: string,
    buttonTextAccept: string,
    errorPolicyText: string,
    nextPage: string
  },
  contactPage: {
    deliveryTitle: string,
    deliveryDescription: string,
    phoneSubtitle: string,
    phonePlaceholder: string,
    dobSubtitle: string,
    dobPlaceholder: string,
    checkboxText: string,
    continueButton: string,
    backPage: string,
    nextPage: string,
  },
  otpPage: {
    deliveryTitle: string,
    deliveryDescription: string,
    continueButton: string,
    backPage: string,
    nextPage: string,
  },
  instructions: {
    title: string,
    steps: { step: string }[]
  },
  uploadPage: {
    title: string,
    description: string,
    subTitle: string,
    guidelines: {
      guideImage: string,
      title: string,
      text1: string,
      text2: string,
      text3: string,
      text4: string,
      text5: string
    }[],
    deliveryTitle: string,
    contactPlaceholder: string,
    deliveryDescription: string,
    contactTitle: string,
    uploadButtonText: string,
    supportedFormats: string,
    iconClose: { src: string, alt: string },
    finalizeButtonText: string,
    checkboxText: string,
    backPage: string,
    nextPage: string
  },
  processingPage: {
    titleProcessing: string,
    title: string,
    description: string,
    estimatedTime: number,
    contactInfo: string
  },
  sharePage: {
    title: string,
    subTitle: string,
    description: string,
    shareButton: string,
    backPage: string,
    contactInfo: string,
    downloadButtonDesktop: { src: string, alt: string },
    downloadButtonMobile: { src: string, alt: string },
  },
  videoPlayer: {
    playButton: { src: string, alt: string },
    pauseButton: { src: string, alt: string },
  },
  privacyModalText: {
    title: string;
    text: {
      elements: Array<{
        type: string;
        content: string | string[];
        class?: string;
      }>
    }
  },
  termsModalText: {
    title: string;
    text: {
      elements: Array<{
        type: string;
        content: string | string[];
        class?: string;
      }>
    }
  }
  footer: {
    title: string,
  },
  formPage: {
    title: string,
    subtitle: string,
  },
  verbPage: {
    subtitle: string,
    buttonReg: string,
    buttonNext: string
  },
  questionPage: {},
  imageCaptureHud: {
    arrowBtn: {
      src: string;
      alt: string;
    };
  },
  imageCapturePage: {
    faceOverlay: {
      subTitle: string;
      square: {
        src: string;
        alt: string;
      };
    },
  },
  instructionButton: {
    title: string
  },
  imageConfirmPage: {},
  videoProcessingPage: {},
  videoResultPage: {}
}

export interface DataAppContext {
  data: DataComponents;
  isModalOpen: boolean;
  modalContent: string;
  setModalContent: (content: string) => void;
  currentPage: string;
  setCurrentPage: (page: string) => void;
  start: () => void;
  closeModal: () => void;
  handleSubmit: (nextPage: string) => void;
  setContactInfo: (info: string) => void;
  contactInfo: string;
  setNextPage: (nextPage: string) => void;
  setPreviousPage: (previousPage: string) => void;
  sendOtp: (contactInfo: string) => void;
  sendOtpRequest: SendOtpRequestInfo;
  validateOtp: (otp: string, phoneNumber: string) => Promise<ValidateOtpResponse>;
  validateOtpRequest: ValidateOtpRequestInfo;
  setOtpSent: (sent: boolean) => void;
  setOtp: (otp: string) => void;
  otp: string;
  otpSent: boolean;
  captchaVerified: boolean;
  setCaptchaVerified: (verified: boolean) => void;
  activeModalClass: string;
  setActiveModalClass: (className: string) => void;
  modalType: 'PrivacyModal' | 'TermsModal';
  setModalType: (type: 'PrivacyModal' | 'TermsModal') => void;
  modalPath: 'CookiePolicy' | 'Footer' | 'ContactPage' | '';
  setModalPath: (path: 'CookiePolicy' | 'Footer' | 'ContactPage' | '') => void;
  openModal: (path?: 'CookiePolicy' | 'Footer' | 'ContactPage' | '') => void;
  username: string;
  setUsername: (username: string) => void;
  dob: string,
  setDob: (dob: string) => void,
  consent: boolean,
  setConsent: (consent: boolean) => void,
  verb: Verb;
  setVerb: (verb: Verb) => void;
  expression: Expression;
  setExpression: (expression: Expression) => void;
  expressionIndex: number;
  setExpressionIndex: (index: number) => void;
  userImage: string;
  setUserImage: (userImage: string) => void;
  stepProgress: number;
  setStepProgress: (progress: number) => void;
  stepTotal: number;
  setStepTotal: (total: number) => void;
  outputVideo: string;
  setOutputVideo: (outputVideo: string) => void;
  openInstructionsModal: (event: MouseEvent) => void;
  closeInstructionsModal: () => void;
  isOverlayVisible: boolean;
  setIsOverlayVisible: (visible: boolean) => void;
  isCameraPermissionGranted: boolean;
  setIsCameraPermissionGranted: React.Dispatch<React.SetStateAction<boolean>>;
  displayImageConfirmPage: boolean;
  setDisplayImageConfirmPage: React.Dispatch<React.SetStateAction<boolean>>;
  displayCameraDeniedPage: boolean;
  setDisplayCameraDeniedPage: React.Dispatch<React.SetStateAction<boolean>>;
  openEnableCameraModal: () => void;
  login: (userData: { name: string; dob: string; consent: boolean}) => boolean;
  logout: () => void;
  isLoggedIn: boolean;
  goToVerbPage: () => void;
}

export const AppContext = createContext<DataAppContext | undefined>(undefined);


export default AppContext;
