import React, { useCallback, useContext } from 'react'
import { ErrorMessage, Field } from 'formik'
import classNames from 'classnames'
import AppContext from '../../../AppContext'
import './Checkbox.scss'

interface CheckboxProps {
  name: string
  className?: string
  isLoggedIn: boolean
}

export const Checkbox = (props: CheckboxProps) => {
  const { name, className, isLoggedIn } = props
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('FormPage must be used within an AppProvider')
  }
  const { setModalContent, openModal } = context

  const handleModalOpen = useCallback(
    (type: 'PrivacyModal' | 'TermsModal', event: any) => {
      event.preventDefault()
      setModalContent(type)
      openModal('ContactPage')
    },
    [setModalContent, openModal]
  )

  return (
    <div className={classNames('dob-page__checkboxes', className, { visibilityHidden: isLoggedIn })}>
      <div className='dob-page__field-container'>
        <div className='dob-page__checkbox-wrapper'>
          <Field type='checkbox' name={name} id={name}/>
          <label htmlFor={name} className='dob-page__checkbox-label'></label>
        </div>
        <label className='dob-page__checkbox-description'>
          I certify that I am at least 18 years old and have read and agree with the
          <button type='button' className='buttonLink' onClick={(e) => handleModalOpen('TermsModal', e)}>
            Terms and Conditions
          </button>
          and
          <button type='button' className='buttonLink' onClick={(e) => handleModalOpen('PrivacyModal', e)}>
            Privacy Policy
          </button>
        </label>
      </div>
      <ErrorMessage name={name} component='div' className='error'/>
    </div>
  )
}
