import React from 'react'
import './UnsupportedBrowsers.scss'

export const UnsupportedBrowsers = () => {
  return (
    <div className="UnsupportedBrowsers">
      For the best experience please choose another browser.
    </div>
  )
}
