import { getSessionId, getStoredOtp, getStoredPhoneNumber } from "../utils/storageUtils"

export interface ValidateImageRequestData {
  sessionId?: string
  imageData: string
  phoneNumber?: string
  pin?: number
}

export interface ValidateImageResponse {
  status: 'success'
}

export interface ValidateImageError {
  status: 'invalid sessionId' | 'invalid image data' | 'server error'
}

export const getValidateImageUrl = () => {
  return `${process.env.REACT_APP_API_BASE_URL}/validate-image`
}

export const validateImage = (data: ValidateImageRequestData): Promise<ValidateImageResponse> => {
  if (!data.sessionId) {
    data.sessionId = getSessionId()
  }

  if (!data.phoneNumber) {
    data.phoneNumber = getStoredPhoneNumber()
  }

  if (!data.pin) {
    data.pin = parseInt(getStoredOtp())
  }

  return fetch(getValidateImageUrl(), {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      'Content-Type': "application/json",
    }),
    body: JSON.stringify(data)
  }).then((response) => response.json())
}