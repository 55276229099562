import React, { useContext } from 'react'
import openInstructions from '../../assets/instructionsIcon.svg'
import logo from '../../assets/logo.png'
import './InstructionButton.scss'
import AppContext from '../../AppContext'
import { useIsMobile } from '../../utils/viewport'
import classNames from 'classnames'

type InstructionsButtonProps = {
  showTitle?: boolean
}

export const InstructionsButton = ({ showTitle }: InstructionsButtonProps) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }
  const {
    openInstructionsModal,
    displayCameraDeniedPage,
    displayImageConfirmPage
  } = useContext(AppContext)!
  const isMobile = useIsMobile()

  const marginLogo = showTitle ? 'instructions-button__logo titleShow' : 'instructions-button__logo'

  return (
    <div className="instructions-button">
      <button
        className={classNames('instructions-button__open', {
          'instructions-button__open__displayButton':
            displayCameraDeniedPage || displayImageConfirmPage
        })}
        type="button"
        onClick={openInstructionsModal}
      >
        <img src={openInstructions} alt="open modal" />
      </button>
      <img className={marginLogo} src={logo} alt='open modal'/>

      {!isMobile && showTitle && <p className='instructions-button__title'>
        {context.data.instructionButton.title}</p>
      }
    </div>
  )
}

export default InstructionsButton
