import React from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import './TextInput.scss'

interface TextInputProps {
  label: string
  name: string
  type?: string
}

export const TextInput = (props: TextInputProps) => {
  const { label, name, type = 'text'} = props
  const { setFieldValue, values } = useFormikContext<any>()

  return (
    <label className='TextInput'>
      <h2>{label}</h2>
      <Field
        name={name}
        type={type}
        value={values[name]}
        onInput={(e: any) => {
          setFieldValue(name, e.target.value)
        }}
      />
      <ErrorMessage name={name} component='div' className='error' />
    </label>
  )
}
