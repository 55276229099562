import React, { useContext, useEffect, useMemo } from 'react'
import { CookiePolicy } from '../CookiePolicy/CookiePolicy'
import { PrivacyAndTermsModal } from '../PrivacyAndTermsModal/PrivacyAndTermsModal'
import { InstructionsModal } from '../InstructionsModal/InstructionsModal'
import AppContext from '../../AppContext'
import { EnableCameraModal } from '../ImageCapturePage/EnableCameraModal/EnableCameraModal'

type ModalName = 'CookiePolicy' | 'PrivacyModal' | 'TermsModal' | 'instructions' | "EnableCamera"

export const ModalFlow = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }

  const {modalContent, data, setActiveModalClass} = context

  const modalClassNames: any = useMemo(() => ({
    'CookiePolicy': 'cookie-policy-modal',
    'PrivacyModal': 'privacy-modal',
    'TermsModal': 'terms-modal',
    'Instructions': 'instructions-modal',
    "EnableCamera": 'enable-camera-modal'
  }), [])

  const modalComponents: any = {
    'CookiePolicy': CookiePolicy,
    'PrivacyModal': () => <PrivacyAndTermsModal type='PrivacyModal'/>,
    'TermsModal': () => <PrivacyAndTermsModal type='TermsModal'/>,
    'Instructions': InstructionsModal,
    'EnableCamera': EnableCameraModal
  }

  useEffect(() => {
    const modalClassName = modalClassNames[modalContent as ModalName]
    setActiveModalClass(modalClassName || '')
  }, [modalContent, setActiveModalClass, modalClassNames])

  const ModalComponent = modalComponents[modalContent as ModalName] || (() => null)


  return ModalComponent === CookiePolicy
    ? <ModalComponent data={data.cookiePolicy}/>
    : <ModalComponent/>
}
