import React, { useContext, useEffect } from 'react'
import AFRAME from 'aframe'
import AppContext from '../../AppContext'

// A React component for 8th Wall AFrame scenes. The scene HTML can be supplied, along with
// any components or primitives that should be registered, and any image targets that should be
// loaded if something other than the automatically loaded set is wanted.

// Helper function to make sure that aframe components are only registered once, since they can't
// be cleanly unregistered.
const registeredComponents = new Set()
const registerComponents = (components: NamedAframeObj<AFRAME.Component>[]) => components.forEach(({name, val}) => {
  if (registeredComponents.has(name)) {
    return
  }

  registeredComponents.add(name)
  AFRAME.registerComponent(name, val)
})

// Helper function to make sure that aframe primitives are only registered once, since they can't
// be cleanly unregistered.
const registeredPrimitives = new Set()
const registerPrimitives = (primitives: NamedAframeObj<AFRAME.PrimitiveDefinition>[]) => primitives.forEach(({name, val}) => {
  if (registeredPrimitives.has(name)) {
    return
  }

  registeredPrimitives.add(name)
  AFRAME.registerPrimitive(name, val)
})

// Helper function to make sure that aframe systems are only registered once, since they can't
// be cleanly unregistered
const registeredSystems = new Set()
const registerSystems = (systems: NamedAframeObj<AFRAME.System>[]) => systems.forEach(({name, val}) => {
  if (registeredSystems.has(name)) return

  registeredSystems.add(name)
  AFRAME.registerSystem(name, val)
})

interface NamedAframeObj<T> {
  name: string
  val: T
}

interface AFrameSceneProps {
  sceneHtml: string
  imageTargets?: string[]
  components?: NamedAframeObj<AFRAME.Component>[]
  primitives?: NamedAframeObj<AFRAME.PrimitiveDefinition>[]
  systems?: NamedAframeObj<AFRAME.System>[]
  onLoaded?: () => void
}

// A react component for loading and unloading an aframe scene. The initial scene contents should
// be specified as an html string in sceneHtml. All props must be specified when the component
// mounts. Updates to props will be ignored.
//
// Optionally, aframe coponents to register for this scene can be passed as [{name, val}] arrays.
// Care is needed here to not define the same component different across scenes, since aframe
// components can't be unloaded.
//
// Optionally imageTargets can be specified to override the set loaded by default.
function AframeScene({sceneHtml, imageTargets, components, primitives, systems, onLoaded}: AFrameSceneProps) {
  const context = useContext(AppContext);

  useEffect(() => {
    const handleLoaded = () => {
      if (imageTargets) {
        window.XR8.XrController.configure({imageTargets})
      }

      onLoaded?.()
    }

    if (systems) {
      registerSystems(systems)
    }

    if (components) {
      registerComponents(components)
    }

    if (primitives) {
      registerPrimitives(primitives)
    }

    const html = document.getElementsByTagName('html')[0]
    const origHtmlClass = html.className
    document.body.insertAdjacentHTML('beforeend', sceneHtml)

    window.XR8 ? handleLoaded() : window.addEventListener('xrloaded', handleLoaded)

    // Cleanup
    return () => {
      console.log('Calling AFrameScene cleanup.')

      const ascene = document.getElementsByTagName('a-scene')[0]
      ascene?.parentNode?.removeChild(ascene)
      html.className = origHtmlClass
      window.removeEventListener('xrloaded', handleLoaded)
      console.log('Finished calling AFrameScene cleanup.')
    }
  }, [components, imageTargets, onLoaded, primitives, sceneHtml, systems])

  useEffect(() => {
    window.XR8.addCameraPipelineModule({
      name: 'cameraPermissionHandler',
      onCameraStatusChange: ({ status }) => {
        if (status === 'hasVideo') {
          if (!context) {
            throw new Error('AppContext is undefined. Ensure it is provided by a Provider.');
          }
          context.setIsCameraPermissionGranted(true); // This will indicate that camera permission has been granted
        }
      },
    });

    return () => {
      window.XR8.removeCameraPipelineModule('cameraPermissionHandler');
    }
  }, [context])

  return (
    <React.Fragment></React.Fragment>
  )

}

export { AframeScene }
