import { v4 as uuid } from 'uuid'
import { ValidateOtpResponse, validateOtp } from '../api/validateOtp'

const sessionIdKey = 'MMPO_sessionId'

export const getSessionId = (): string => {
  try {
    const currentSessionId = window.localStorage.getItem(sessionIdKey)

    if (currentSessionId) return currentSessionId
    const newSessionId = uuid()
    window.localStorage.setItem(sessionIdKey, newSessionId)

    return newSessionId
  } catch (error) {
    return ''
  }
}

const phoneNumberKey = 'MMPO_phoneNumber'
export const setStoredPhoneNumber = (phoneNumber: string) => {
  localStorage.setItem(phoneNumberKey, phoneNumber)
}
export const getStoredPhoneNumber = (): string => {
  return localStorage.getItem(phoneNumberKey) || ''
}

const otpKey = 'MMPO_otp'
export const setStoredOtp = (otp: string) => {
  localStorage.setItem(otpKey, otp)
}
export const getStoredOtp = (): string => {
  return localStorage.getItem(otpKey) || ''
}

export interface ContactInfo {
  contact: string
  dob: string
  consent: boolean
}

export const hasValidStoredInfo = async (): Promise<ValidateOtpResponse["data"] | undefined> => {
  const storedPhoneNumber = getStoredPhoneNumber()
  const storedOtp: string = getStoredOtp()

  if (!storedPhoneNumber) {
    return Promise.resolve(undefined)
  }

  return validateOtp({ pin: parseInt(storedOtp), phoneNumber: storedPhoneNumber })
    .then(response => {
      if (response.status === 'success' && response.data.name && response.data.consent) {
        return response.data
      } else {
        return undefined
      }
    })
    .catch(() => undefined)
}

export const getCdsUserIdentifier = (): string | undefined => {
  return document.cookie
    .split('; ')
    .find(cookieLine => cookieLine.startsWith('__cds_pixel_id'))
    ?.split('=')[1]
}