import { getCdsUserIdentifier } from "../utils/storageUtils"
import { CDSGeoData, getCachedGeoData } from "./geoIp"

export interface ValidateOtpRequestData {
  pin: number
  phoneNumber: string
  cdsUserIdentifier?: string
  geoData?: CDSGeoData
}

export interface ValidateOtpResponse {
  status: 'success',
  data: {
    name: string,
    dob: string,
    consent: boolean
  }
}

export interface ValidateOtpError {
  status: 'invalid sessionId' | 'invalid otp' | 'server error'
}

export const getValidateOtpUrl = () => {
  return `${process.env.REACT_APP_API_BASE_URL}/verify-otp`
}

export const validateOtp = async (data: ValidateOtpRequestData): Promise<ValidateOtpResponse> => {
  data.cdsUserIdentifier = getCdsUserIdentifier()
  data.geoData = await getCachedGeoData()

  return fetch(getValidateOtpUrl(), {
    method: 'POST',
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      'Content-Type': "application/json",
    }),
    body: JSON.stringify(data)
  }).then((response) => response.json())
}