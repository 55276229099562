import React, { useCallback, useContext } from 'react'
import AppContext, { DataComponents } from '../../AppContext'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import './CookiePolicy.scss'

export const CookiePolicy = ({data}: { data: DataComponents['cookiePolicy'] }) => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useContext must be used within a Provider')
  }
  const {setNextPage, setModalContent, openModal, closeModal} = context

  const handleModalOpen = useCallback(
    (type: 'PrivacyModal' | 'TermsModal', event: any) => {
      event.preventDefault()
      setModalContent(type)
      openModal('CookiePolicy')
    },
    [setModalContent, openModal]
  )


  const validationSchema = Yup.object({
    policyAccepted: Yup.bool().oneOf([true], data.errorPolicyText)
  })

  return (
    <Formik
      initialValues={{
        policyAccepted: false,
        contact: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        setNextPage(data.nextPage)
        setSubmitting(false)
        resetForm()
        closeModal()
      }}
    >
      {({isSubmitting}) => (
        <div className='cookie-policy'>
          <div className='cookie-policy__container'>
            <Form>
              <h2 className='cookie-policy__title'>{data.title}</h2>
              <div className='cookie-policy__checkboxes'>
                <div className='cookie-policy__field-container'>
                  <div>
                    <Field type='checkbox' name='policyAccepted' id='policyAccepted'/>
                    <label htmlFor='policyAccepted' className='inline-label'></label>
                  </div>
                  <label>
                    <div onClick={(e) => e.preventDefault()}>
                      {data.message}
                      <button type='button' className='buttonLink' onClick={(e) => handleModalOpen('TermsModal', e)}>
                        T&C
                      </button>
                      {` and `}
                      <button type='button' className='buttonLink' onClick={(e) => handleModalOpen('PrivacyModal', e)}>
                        Privacy Policy
                      </button>
                      .
                    </div>
                  </label>
                </div>
                <ErrorMessage name='policyAccepted' component='div' className='error'/>
              </div>

              <button className='blackButton'
                      type='submit'
                      disabled={isSubmitting}>
                {data.buttonTextAccept}
              </button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  )
}
