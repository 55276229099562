import classNames from "classnames"
import './LoadingIcon.scss'

export interface LoadingIconProps {
  className?: string
  color?: string
}

export const LoadingIcon = (props: LoadingIconProps) => {
  const {
    className,
    color = 'white'
  } = props
  const loadingIconClasses = classNames('LoadingIcon', className)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={loadingIconClasses}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 3a9 9 0 1 0 9 9"></path>
    </svg>
  )
}
